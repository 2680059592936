@import "../../../../../styles/colors";

.tags-table{
    th {
        font-weight: 500;
        background-color: #f4f4f4;
        border: none;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
    }

    tr {
        &:hover{
            background : $primary-color-light-3;
        }
    }
}

.tag-edit-btn {
    color: $primary-color;
    font-size: 14px;
    :hover{
        text-decoration: underline;
    }
    
    svg{
        margin-top: -3px;
    }
}

.tag-delete-btn {
    color: $error-color;
    font-size: 14px;
    :hover{
        text-decoration: underline;
    }
    svg{
        margin-top: -3px;
    }
}

.add-tag-form{
    background-color: $primary-color-light;
    padding: 25px 30px;
    margin-bottom: 10px;

    .label{
        font-size: 14px;
        font-weight: 600;
        color: $secondary-text;
    }
    
}