@import './colors';

/* ---------------- Tag styling ---------------- */
* {
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease !important;
}

@font-face {
	font-family: "PoppinsM";
	src: url("../assets/fonts/Poppins-Medium.woff2") format("woff");
	font-weight: normal;
	font-style: normal; 
}

@font-face {
	font-family: "PoppinsR";
	src: url("../assets/fonts/Poppins-Regular.woff2") format("woff");
	font-weight: normal;
	font-style: normal; 
}

@font-face {
	font-family: "FiraM";
	src: url("../assets/fonts/FiraM.woff") format("woff");
	font-weight: normal;
	font-style: normal; 
}

@font-face {
	font-family: "FiraR";
	src: url("../assets/fonts/FiraR.woff") format("woff");
	font-weight: normal;
	font-style: normal; 
}

body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p {
	font-size: 14px;
}



/* ---------------- Common Classes ---------------- */
.wrapper {
	display: flex;
}

.main-container {
	width: calc(100% - 240px) ;
	min-height: 100vh;
	height: 100%;
	background-color: rgba(244, 244, 251, 0.6);
	// background-color: $main-light-background;
	margin-left: 240px;

	&.sb-collapsed {
		margin-left: 40px;
		width: calc(100% - 40px) ;
	}
}

.logo {
	width: 200px;
	margin: 12px 16px;
	padding: 6px 0;

	&:focus {
		outline: 0;
	}
}

.icon-container {
	cursor: pointer;
	background: #5330b91a;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	width: 26px;
	height: 26px;
	margin: 0 8px;
	padding: 0;
}

.legend:hover{
	cursor: pointer;
}

.date-container {
	cursor: pointer;
	background: #5330b91a;
	border-radius: 10%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	padding: 1;
	margin: 0 8px;
}

.icon-container-disabled {
	cursor: not-allowed;
	background: $secondary-button-hover;
}

.icon-container-hover {
	&:hover {
		box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.2);
		transform: scale(1.05);
	}
}

.delete-icon-container {
	background: $error-light-color;
}

.app--navbar {
	padding: 18px;

	.small--heading {
		margin-bottom: 0;
		font-size: 12px;
		color: #a19eaa;
	}

	hr {
		margin: 0;
	}
}

.card--container {
	border: 1px solid $border-color;
	border-radius: 4px;
	background-color: $white;
	padding: 12px;

	.card--title {
		margin-bottom: 0;
		color: $primary-text;
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
	}
}

.home-card {
	min-height: calc(100vh - 195px);
	margin: 18px 18px 6px;
}

.error-container {
	height: 65vh;
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	background-color: $primary-color-light;
}

.tabledata-container{
	height: 33vh;
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	background-color: $primary-color-light;
}

.pagination-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 1.5rem;

	p {
		margin: 0 1rem;
	}
}

.job-node-table {
	max-height: 450px;
	overflow: auto;
}

.image-details-column {
	margin: 12px 0;

	a {
		color: $primary-color;
		display: block;
		max-width: 100%;
		word-wrap: break-word;
		font-size: 14px;

		&:hover {
			color: $primary-color;
		}
	}
}

.progress {
	height: 16px;
	font-size: 13px;
}

.in-progress {
	background: #4fc3f7;
	color: $primary-text;
}
.triggered {
	background: #ffb74d;
	color: $primary-text;
}
.succeded {
	background: #81c784;
	color: $primary-text;
}
.failed {
	background: #e57373;
	color: $primary-text;
}

.advanced-section {
	padding: 16px;
	background-color: $primary-color-light;
	margin-bottom: 16px;
}

.change-password-column {
	max-width: 500px;
}

.tenant-view {
	.tenant-view-info-bar-wrapper {
		position: fixed;
		height: 36px;
		width: 100%;
		background-color: #4cc800;
		z-index: 999;
		display: flex;
	}

	.wrapper {
		.sidebar, .main-container {
			margin-top: 36px;
		}

		.sidebar {
			height: calc(100vh - 36px) !important;
		}
	}
}

/* ---------------- Custom Utility Classes ---------------- */
.h-90 {
	height: 90%;
	max-height: 90%;
}

.color-primary {
	color: $primary-color;
}

.icon-primary {
	color: $primary-color !important;
}

.footer {
	p,
	a {
		font-size: 13px !important;
	}

	a {
		text-decoration: underline;
	}
}

.table-div {
	display: inline-block;
	overflow: auto;
	max-width: 12vw;
}

.settings-subsection {
	border: 1px solid $border-color;
	padding: 12px;

	h1 {
		font-size: 16px;
		transition: none;
	}
}

.grayout{
	opacity: 0.1;
	cursor: not-allowed;
}
.data-info{
	z-index: 2;
}

.text-align-right {
	text-align: right;
}

.text-small {
	font-size: 0.8em;
}

.alert-light .highlight {
	color: #686868;
	font-weight: bold;
}

.card-heading {
	display: flex;
	letter-spacing: 0.5px;
	color: #333;
	
	.highlight {
		font-weight: 500;
	}

	.title {
		.primary, .secondary {
			padding-right: 5px;
		}
	}

	& > div:not(:last-child)::after {
		border-right: 1px solid #dedede;
		height: 20px;
		width: 20px;
		content: " ";
		margin: 0 10px;
	}
}

.p-relative {
	position: relative;
}

.p-absolute {
	position: absolute;
}

.p-fixed {
	position: fixed;
}

.top-0 {
	top: 0;
}

.bottom-0 {
	bottom: 0;
}

.left-0 {
	left: 0;
}

.right-0 {
	right: 0;
}

/* ---------------cursor pointer ---------------- */

.cursor-pointer{
	cursor: pointer;
}


/* ---------------- Toottip ----------------------*/

.tooltip-container:hover{
	position: relative;
	.tooltiptext{
		visibility: visible;
	}
}

/* Tooltip text */
.tooltiptext {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	padding: 5px 5px;
	border-radius: 6px;
	position: absolute;
	z-index: 1;
	margin-top: 30px;
	font-size: 12px;

	&::after{
		content: " ";
		position: absolute;
		bottom: 100%;  /* At the top of the tooltip */
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: transparent transparent black transparent;
	}

}
  

.info-message-container{
	height: 150px;
	border-radius: 4px;
	border: 1px solid $border-color;
	background-color: #ffff;
}

tspan{
    fill: black;
    font-weight: 400 !important;
}

.font-unset {
	font-size: unset !important;
}

.z-index-1050{
	z-index: 1050;
}

@keyframes loadingBar {
	0% {
		left: 0%;
		right: 100%;
		width: 0%;
	}

	10% {
		left: 0%;
		right: 75%;
		width: 25%;
	}

	90% {
		right: 0%;
		left: 75%;
		width: 25%;
	}

	100% {
		left: 100%;
		right: 0%;
		width: 0%;
	}
}