@import './../../../../../../styles/colors';

.distribution-list {
    .item-row {
        padding: 0px;
        display: flex;
        font-size: 80%;
        border: none;
        border-bottom: 1px solid transparent;
        background-color: transparent !important;

        &.header {
            border-bottom: 1px solid $border-color;
            .item {
                font-weight: 500;
            }

            &, &:hover {
                .box {
                    background: transparent;
                    cursor: default;
                }
                .text, .count {
                    color: $primary-text !important;
                    text-decoration: none !important;
                    cursor: default;
                    font-size: 110%;
                }
            }
        }

        .box {
            padding: 10px;
            display: flex;
            flex-grow: 1;
            cursor: pointer;
            border: 1px solid transparent;

            &:hover {
                background-color: $primary-color-light-2;
            }
        }

        .text {
            color: $primary-text;
            display: flex;
            align-items: center;
        }

        .count {
            flex-grow: 1;
            min-width: 25%;
            font-weight: 500;
            color: $secondary-text;
            display: flex;
            justify-content: right;
            align-items: center;
            font-size: 110% !important;
        }

        .expand {
            min-width: 30px;
            display: flex;
            align-items: center;
            justify-content: right;

            svg {
                color: $primary-color;
            }
        }
    }
}