@import './../../../../../../../styles/colors';

.node-log-wrapper {
    padding: 7px;
    cursor: pointer;
    margin: 1px 0px;

    .preview {
        display: flex;
        font-size: 12px;
        align-items: center;
        color: $primary-text;
        letter-spacing: -0.5px;

        .tag {
            width: 8%;
        }

        .ts {
            width: 22%;
            color: $muted-text;
            font-family: "FiraM";
        }

        .msg {
            width: 55%;
            font-family: "FiraR";
            position: relative;

            > div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                position: absolute;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .actions {
            flex-grow: 1;
            justify-content: right;
            display: flex;

            a {
                color: $muted-text;
            }

            button {
                background: transparent;
                border: none;
                outline: none;
                padding: 0px;
                width: 30px;
                
                
                svg {
                    color: $muted-text;
                }

                &:hover {
                    svg {
                        color: $primary-color-dark;
                    }    
                }

                &:first-child {
                    margin-left: 20px;
                }
            }
        }
    }

    &:hover, &.detailed-view {
        background-color: $primary-color-light-3;
    }
}