@import './../../../../../styles/colors';

.no-data {
    text-align: center;
    padding: 10px;

    h6 {
        color: $primary-text;
        font-size: 14px;
        font-weight: 500;
    }

    p {
        color: $secondary-text;
        font-weight: 300;
        font-size: 13px;
    }
}