@import './../../../../../../../styles/colors';

.usage-insights-toolbar-wrapper {
    padding-left: 5px;
    .actions {
        display: flex;

        .action {
            display: flex;
            align-items: center;
            padding: 10px;

            &:not(:last-child) {
                border-right: 0.5px dotted $divider-color;
            }

            &.month-select {
                width: auto;

                .month-picker-input {
                    border: none;
                    outline: none;
                    padding: 5px;
                    width: 100%;
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0px;
                    cursor: pointer;
                    background: transparent;
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    background: #edebf8 !important;
                    color: $primary-color;
                    border: 1px solid $primary-color;

                    svg {
                        margin-right: 5px;
                    }

                    label {
                        margin: 0px;
                        cursor: pointer;
                    }
                }


                .react-datepicker-wrapper {
                    display: flex;
                    align-items: center;
                }

                .react-datepicker {
                    border-radius: 0px;
                    font-family: sans-serif;
                    font-size: 12px;

                    .react-datepicker__navigation-icon {
                        font-size: inherit;
                    }

                    .react-datepicker-year-header {
                        background-color: $primary-color-light-2;
                    }

                    .react-datepicker__month-wrapper {
                        margin-bottom: 10px;

                        .react-datepicker__month-text:hover {
                            border-radius: 0px !important;
                        }

                        .react-datepicker__month--selected {
                            border-radius: 0px;
                            background-color: $primary-color-light-2;
                            color: $primary-color;
                        }
                    }
                }
            }
        }
    }
}