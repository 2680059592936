@import "./../../../../../styles/colors";

.setting-panel-wrapper {
  &.root {
    border: 1px solid $border-color;
    margin-bottom: 24px;
    border-radius: 6px;

    & > .setting-items {
      margin: 18px;
    }
  }

  .setting-items {
    &.owner {
      margin: 8px 0px 0px 16px;
    }

    &.owner ~ .tenant {
      border-left: 1px solid $border-color;
      margin-left: 18px;
      > .nested {
        .setting-panel-wrapper {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            bottom: 50%;
            transform: translate(-50%, -50%);
            width: 16px;
            height: 1px;
            left: -8px;
            background-color: $border-color;
          }
        }
      }

      &:last-child {
        border-image: linear-gradient(
          to bottom,
          $border-color 50%,
          transparent 10%,
          $border-color 50%,
          transparent 60%
        );
        border-image-slice: 1;
      }

      & > div {
        padding: 6px 0;
      }
    }

    .setting-item {
      .title-container {
        display: flex;

        svg {
          margin-right: 6px;
          color: $divider-color;
        }
        h6 {
          margin: 0;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: -0.2px;
          line-height: 32px;
          color: $muted-text;
        }
      }
    }
  }
}
