@import './../../../../../../styles/colors';

.current-month-usage-wrapper {
    .row {
        // border-bottom: 1px solid $border-color;
        margin: 0;
        padding: 10px 0px;
        overflow: hidden;

        &.current-usage-data > .col {
            min-height: 165px;
            padding: 0px;
            margin: 0px -25px;
            max-width: unset;

            .chart {
                position: absolute;
                width: 98%;
                height: 170px;
                left: 0%;
                top: 0;
                margin: auto 0;
            }

            .total-count {
                position: absolute;
                top: 0;
                left: 35px;
                margin: auto 0;
            }
        }
    }
    h1 {
        color: $primary-text;
        margin: 0px;
        font-family: "PoppinsM";
        letter-spacing: 0px;
        font-weight: 600;
        line-height: normal;
    }

    label {
        font-size: 16px;
        font-weight: 600;
        color: #807aa4;
        letter-spacing: -0.5px;
        margin-bottom: 0px;
        line-height: 36px;
    }
}