.log-message-wrapper {
    padding-bottom: 10px;
    border-bottom: 1px dashed #505765;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .text {
        line-height: 24px;
    }
}