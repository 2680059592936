@import './../../../../../../styles/colors';

.simple-select-dd {
    display: flex;
    align-items: center;

    .icon {
        margin-right: 3px;
        display: flex;
        align-items: center;
        svg {
            color: $primary-color;
        }
    }

    label {
        font-size: 12px;
        font-weight: 500;
        margin: 0px;
        color: $primary-color;
    }
}