@import './../../../../styles/colors';

.add-filter-container {
    .add-filter.dropdown .primary-button.dropdown-toggle.btn.btn-primary {
        background-color: $primary-color;
        color: $white;
        border: 1px solid $primary-color-dark;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-color: transparent;
        display: flex;
        font-weight: 600;
        font-size: 14px;
    }

    .menu-content.dropdown-menu {
        box-shadow: 1px 3px 6px #0000000d;
        border-radius: 0px;
        border: 1px solid $border-color;
    }

    .primary-button.dropdown-toggle.btn.btn-primary {
        &:active, &:focus {
            color: $primary-color-light !important;
		    background-color: $primary-color !important;
            box-shadow: none !important;
        }

        &:disabled {
            color: $primary-color-light;
            background-color: $secondary-text;
            cursor: not-allowed;
            border: 1px solid $secondary-text; 
        }

        &::after {
            display: none;
        }

        svg {
            font-size: 12px;
        }
    }
}