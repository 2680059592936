@import './../../../../../../styles/colors';

.tabs-header {
    display: flex;

    .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: $primary-text;
        font-size: 95%;
    }

    .actions {
        display: flex;
        flex-grow: 1;
        justify-content: right;

        .tab-selector {
            min-width: 50px;
            display: flex;
            justify-content: center;
            background-color: transparent;
            outline: none;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 3px solid;
            border-color: transparent;
            padding: 5px;

            svg {
                color: $secondary-text;
            }

            &.active {
                border-color: $primary-color-dark;

                svg {
                    color: $primary-color;
                }
            }

            &:hover {
                &:not(.active) {
                    svg {
                        color: $primary-color;
                    }
                }
            }
        }
    }
}