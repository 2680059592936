@import '../../styles/colors';

.node-tag {
    display: inline-block;
    margin: 15px 15px 5px 0px;
    font-size: 80%;
    cursor: pointer;
    
    .tag-container {
        display: flex;
        border: 1px solid #e0e0fb;
        border-radius: 5px;
        box-shadow: 1px 1px 10px #ebebfc;
        transition: 0.2s;

        .property, .value {
            border: 1px solid transparent;
        }

        .property {
            padding: 2px 6px;
            letter-spacing: -0.5px;
            background: linear-gradient(90deg, #503996, #6f4dd2);
            color: $primary-color-light-3;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            font-weight: 300;
        }
        .value {
            letter-spacing: 0.5px;
            padding: 2px 8px;
            background: linear-gradient(90deg, $primary-color-light-3, $primary-color-light-2);
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            color: $primary-color;
        }

        &:hover{
            border-color: $primary-color;
        }
    }
}