@import './../../../../../../../styles/colors';

.breakdown-details-wrapper {
    display: flex;
    border-top: 0.5px dotted $muted-text;
    margin-top: 5px;
    cursor: default;
    background-color: $primary-color-light-2;

    .details {
        width: 90%;
        
        .item {
            margin: 7px 0px;

            &:hover {
                background-color: #bfdefc3e;
            }
    
            .total-count,
            .label,
            .usage {
                font-size: 12px;
                line-height: normal;
                text-align: right;
            }

            .total-count {
                font-family: 'PoppinsR';
                font-weight: 300;
                background: #bfdefc3e;
                color: #405668;
                border-bottom: 1px solid #bfddfc7e;
                text-align: center;
                font-size: 70%;
                width: 20%;
            }
    
            .label {
                color: $secondary-text;
                width: 55%;
            }
    
            .usage {
                font-weight: 600;
                font-family: 'PoppinsM';
                border-left: 0.5px dotted $muted-text;
                width: 25%;
                padding: 5px 0px 0px 0px !important;
                color: $secondary-text;
            }
        }
    }
    
    .empty {
        width: 15%;
    }
}