.placeholder {
	margin: 0 auto;
	max-width: 100%;
	min-height: 60vh;
	background-color: #fff;
}

@keyframes placeHolderShimmer {
	0% {
		background-position: -768px 0;
	}
	100% {
		background-position: 768px 0;
	}
}

.animated-background {
	animation-duration: 1.25s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #edebf8;
	background: linear-gradient(
		to right,
		rgba(83, 48, 185, 0.03) 10%,
		rgba(83, 48, 185, 0.05) 18%,
		rgba(83, 48, 185, 0.03) 33%
	);
	background-size: 800px 104px;
	height: 32px;
	margin: 8px 0;
	position: relative;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}
@keyframes rotating {
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}
.rotating {
	-webkit-animation: rotating 2s linear infinite;
	-moz-animation: rotating 2s linear infinite;
	-ms-animation: rotating 2s linear infinite;
	-o-animation: rotating 2s linear infinite;
	animation: rotating 2s linear infinite;
}

.transition {
	transition: all 0.3s ease-in !important;
}

.loading-overlay {
	pointer-events: none;
	position: fixed;
	top: 60px;
	left: 280px;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.25);
	z-index: 1;
	animation: overlay-open 0.3s ease-in 0.1s;
}

@keyframes overlay-open {
	0% {
		width: 80px;
		height: 0;
	}
	100% {
		width: 100%;
		height: 100%;
	}
}
