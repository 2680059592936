@import './../../styles/colors';

.styled-checkbox {
	position: absolute; // take it out of document flow
	opacity: 0; // hide it

	& + label {
		position: relative;
		cursor: pointer;
		padding: 0;
		font-size: 14px;
	}

	// Box.
	& + label:before {
		content: '';
		margin-right: 6px !important;
		display: inline-block;
		vertical-align: text-top;
		width: 15px;
		height: 15px;
		background: transparent;
		border: 1px solid $secondary-text;
		border-radius: 2px;
	}

	// Box hover
	&:hover + label:before {
		background: $primary-color;
	}

	// Box focus
	&:focus + label:before {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
	}

	// Box checked
	&:checked + label:before {
		background: $primary-color;
	}

	// Disabled state label.
	&:disabled + label {
		// color: #b8b8b8;
		// cursor: auto;
		cursor: default;
	}

	// Disabled box.
	&:disabled + label:before {
		box-shadow: none;
		background: #5330b990;
		cursor: default;
	}

	// Checkmark. Could be replaced with an image
	&:checked + label:after {
		content: '';
		position: absolute;
		left: 2px;
		top: 9px;
		background: white;
		width: 2px;
		height: 2px;
		box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
			4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
		transform: rotate(45deg);
	}
}

.r-checkbox {
	display: inline-block;

	label {
		margin-bottom: 0px !important;
		margin-top: 3px !important;
	}
}

.check {
	margin-right: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 15px;
	height: 15px;
	background: transparent;
	border: 1px solid $secondary-text;
	border-radius: 2px;
	color: $white;
}

.checked {
	transition: none !important;
	background: $primary-color;
	background-image: url('../../assets/checkmark.svg');
	background-repeat: no-repeat;
	background-position: center;
	border: 1px solid $primary-color;
}
