@import "./../../../../../styles/colors";

.group-analytics-pie {
    .details {
        margin-top: 20px;
        background-color: #fff !important;
        box-shadow: 1px 1px 10px $primary-color-light;
        border-radius: 5px;
        border: 1px solid transparent;

        .apexcharts-canvas {
            margin: 0 auto;
        }
    }

    .toolbar {
        display: flex;
        margin-bottom: 20px;
    }
}