@import './../../../../../styles/colors';

.usage-breakdown-wrapper {
    .actions {
        display: flex;
        justify-content: right;
        .action {
            button {
                background: transparent;
                border: none;
                outline: none;
                color: $primary-color;
                font-size: 75%;
                font-weight: 600;
                padding: 5px 10px;
                margin: 10px;
                border-radius: 5px;

                &:hover {
                    background: $primary-color-light-2;
                    text-decoration: underline;
                }

                &:disabled {
                    background-color: $primary-color-light-3;
                    color: $divider-color;
                    text-decoration: none;
                }
            }
        }
    }
}