@import "./../../../../../../styles/colors";

.config-form-wrapper {
  .form {
    display: flex;

    .label-wrapper {
      display: flex;
      align-items: center;

      svg {
        color: $primary-color;
      }

      label {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: $primary-color-dark;
        letter-spacing: 0.2px;
        cursor: pointer;
      }
    }

    .element-wrapper {
      flex-grow: 1;
      display: flex;
      justify-content: right;
    }
  }
}
