@import './colors';

.modal-title {
	font-size: 16px;
	font-weight: 500;
	text-align: center;
}

.success-check {
	background-color: $success-color !important;
	margin: 1rem auto !important;
}

.error-check {
	background-color: $error-color !important;
	margin: 0 auto 1rem !important;
}

.success-info {
	font-size: 14px;
	margin-bottom: 5px;
}

.modal-table-cell {
	border-bottom: 1px solid $border-color;
	padding: 10px;
	text-align: center;
	font-size: 14px;
	text-transform: capitalize;
}

.modal-table-header {
	font-weight: 500;
	background-color: $primary-color-light;
	font-size: 14px;
}

#attachment {
	opacity: 0;
	position: absolute;
	z-index: -1;
}

.upload-file-label {
	cursor: pointer;
	color: $primary-color !important;
	text-decoration: underline;
	margin-bottom: 0;
	font-size: 14px;

	span {
		margin-right: 10px;
	}
}

.modal-imagename {
	position: relative;
	background-color: $primary-color-light;
	color: $primary-color;
	padding: 7px;
	font-size: 14px;

	span.close-icon {
		position: absolute;
		top: -10px;
		right: -10px;
		margin-right: 0;
		cursor: pointer;
	}
}

// .pushImageName {
// 	font-weight: bold;
// 	text-transform: capitalize;
// 	display: inline-block;
// 	margin-bottom: 0;
// 	padding-left: 10px;
// 	font-size: 14px;
// }

.custom-modal {
	.input {
		margin-bottom: 1rem;
	}
}

.modal.show .modal-dialog {
	transform: translateY(-50%) !important;
	top: 50%;
	max-height: 80%;
}

.close {
	font-size: 16px;
	padding: 8px !important	;
	float: right;
}

.start-ota-modal {
	.modal-dialog {
		overflow: visible;
	}
}

// Loader Animation
.loader-modal-container {
	text-align: center;
	padding: 5rem;
}
.lds-ripple-modal {
	display: inline-block;
	position: relative;
	width: 58px;
	height: 64px;
}
.lds-ripple-modal div {
	position: absolute;
	border: 4px solid $primary-color;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple-modal 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple-modal div:nth-child(2) {
	animation-delay: -0.5s;
}
@keyframes lds-ripple-modal {
	0% {
		top: 28px;
		left: 28px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: -1px;
		left: -1px;
		width: 58px;
		height: 58px;
		opacity: 0;
	}
}

// -------------- custom modal -----------------
.popup--modal--container {
	width: 100%;
	height: 100%;
	background: #24292e66;
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	overflow: hidden;

	.custom--modal {
		min-width: 50vw;
		min-height: min-content;
		max-width: 640px;
		box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
		background: $white;
		color: $primary-text;

		position: relative;
		z-index: 100;
		border-radius: 4px;
	}

	.modal--header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-color: $border-color;
		border-style: solid;
		padding: 16px;
	}

	.title {
		color: $primary-text;
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 0;
	}

	.modal--body {
		padding: 0;
		font-size: 14px;
	}

	.modal--footer {
		padding: 16px;
		border-top: 1px solid $border-color;
	}
}


.instructions--modal--container{
	position: relative;
	display: inline-block;
	.modal--body
	{
		display: none;
		position: absolute;
		background-color: #fff;
		border: 1px solid $border-color;;
		padding: 10px;
		min-width: 500px;
		z-index: 1;
		margin-left: -500px;
		border-radius: 5px;
		box-shadow: 1px 1px 5px #ccc;

		ul{
			padding: 0px;
			margin: 0px;
			li{
				list-style: none;
				line-height: 24px;
				padding: 5px;
				font-size: 14px;
			}
		}
	}
}

.instructions--modal--container:hover
{
	.modal--body
	{
		display: block;
	}
}

