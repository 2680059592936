@import './../../../../styles/colors';

.event-types-button-group {
    button, button.primary-button {
        padding-top: 2px;
        padding-bottom: 2px;
        background-color: transparent !important;
        border-color: $primary-color-light-2 !important;
        min-width: 100px !important;
        max-width: 100px !important;
        color: $primary-color-dark !important;
        font-size: 14px;

        &:hover, &.selected {
            color: $primary-color-dark !important;
            background-color: $primary-color-light-2 !important;

            &:disabled {
                background-color: transparent !important;
            }
        }
    }

    .dropdown-menu {
        border-color: transparent !important;
        background-color: #fff !important;
        min-width: auto !important;
        padding: 0px;
        margin-top: 5px !important;

        & > a {
            padding: 0px;
        }

        & > a:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}