@import './../../../../../../../../styles/colors';

.log-details-wrapper {
    background: linear-gradient(270deg, #282c34, #323741);
    margin: 10px 0px;
    border-radius: 3px;
    color: $primary-color-light-3;
    padding: 10px;
    font-size: 12px;
    font-family: 'FiraR';
    cursor: text;
}