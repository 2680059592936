.backtrace-wrapper {
    margin-top: 10px;
    h6 {
        margin: 0;
        background-color: #f9f9fe;
        color: #282c34;
        font-family: "FiraM";
        display: inline-block;
        font-size: 100%;
        margin-bottom: 10px;
    }

    .values > div:not(:last-child) {
        border-bottom: 1px dashed #505765;
    }
}