@import "./../../styles/colors";

.switch-input {
  & > input {
    & ~ label {
      &::before {
        box-shadow: none !important;
        border-color: $divider-color !important;
      }
    }
    &:checked {
      & ~ label {
        &::before {
          border-color: $primary-color-dark !important;
          background-color: $primary-color !important;
        }
      }
    }
  }
}
