.cursor-pointer {
	cursor: pointer;
}

.cursor-default {
	cursor: default;
}

.position-relative {
	position: relative;
}

.transition-ease {
	transition: 'all 0.1s ease';
}

.font-weight-500 {
	font-weight: 500;
}

.width-150 {
	width: 150px;
}

.color-primary {
	color: $primary-color !important;
}

.color-secondary {
	color: $primary-text !important;
}
