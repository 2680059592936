.user-accounts-wrapper {
    padding: 10px;
    min-height: 150px;
    
    .header-actions {
        display: flex;
        justify-content: right;
    }

    .accounts-list {
        margin-top: 15px;
    }
}