@import './../../../../../../styles/colors';

.usage-row-wrapper {
    margin-bottom: 2px;

    .usage-row {
        padding: 10px;
        background-color: $main-light-background;
        cursor: pointer;

        .preview {
            display: flex;

            .primary {
                width: 90%;
                display: flex;

                .date {
                    font-weight: 600;
                    letter-spacing: -0.5px;
                    color: $secondary-text;
                    line-height: normal;
                    display: flex;
                    align-items: center;
                }

                .count {
                    display: flex;
                    flex-grow: 1;
                    justify-content: right;
                    align-items: center;
                    font-weight: 600;
                    font-size: 100%;
                    font-family: 'PoppinsM';
                    color: $primary-text;
                    line-height: normal;
                }
            }
            
            .actions {
                width: 15%;
                display: flex;
                justify-content: right;

                .icon {
                    color: $divider-color;
                    margin-left: 15px;
                }
            }
        }

        .details {
        }
    }

    &.simple {
        .usage-row {
            padding: 5px 10px;
            background-color: transparent;
            border-bottom: 0.5px solid $primary-color-light-2;

            .date {
                font-size: 14px;
            }

            .count {
                font-size: 14px !important;
            }

            .icon {
                color: #c2c2d3 !important;
            }

            .details {
                & > div {
                    background-color: transparent;
                }
            }
        }
    }
}