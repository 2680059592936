@import "./../../../../../../styles/colors";

.top-node-wrapper {
    padding: 0px;
    font-size: 14px;
    border-bottom: none;

    &.online, &.online:hover {
        // background-color: #e1f0e8;

        .node.item.icon {
            svg {
                color: $success-dark-color;
            }
        }
    }

    &:hover {
        background-color: $primary-color-light-2;
    }

    .box {
        padding: 15px;

        > div {
            display: flex;
        }

        .icon {
            width: 5%;
            align-items: center;

            &.node {
                justify-content: left;
                color: $muted-text;
            }
            
            &.expand {
                justify-content: right;
                color: $primary-color;
            }
        }

        .text {
            color: $primary-text;
            width: 60%;
        }

        .count {
            font-weight: 500;
            letter-spacing: 0.5px;
            color: $secondary-text;
            width: 10%;
            justify-content: right;
        }

        .actions {
            justify-content: right;
            width: 25%;

            button {
                background: transparent;
                border: none;
                outline: none;
                min-width: 40px;

                svg {
                    color: $muted-text
                }

                &:hover {
                    svg {
                        color: $primary-color-dark;
                    }
                }
            }
        }
    }
}