@import './../../styles/colors';

.multi-select-dropdown,
.multi-select-dropdown.show {
    button.dropdown-toggle.btn.btn-primary {
        background-color: $primary-color;
        cursor: pointer;
        border: 1px solid #8181a8;
        box-shadow: none;

        span {
            padding-right: 5px;
        }
        
        &::after {
            display: none;
        }

        &:hover, 
        &:active, 
        &:focus {
			@extend button.dropdown-toggle.btn.btn-primary
		}
    }

    .menu-item {
        padding: 5px 10px;
    }
}