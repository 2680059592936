@import './../../styles/colors';

.toast-container {
	color: #333 !important;
	border-radius: 3px !important;
	background: #ffffff !important;
	border: 1px solid #f2f2f2 !important;
	border-bottom: 0 !important;
	padding-left: 20px;
	box-shadow: 0px 5px 17px rgba(0, 0, 0, 0.2) !important;
}
.success-progress-bar {
	background: $success-color;
}

.error-progress-bar {
	background: $error-color;
}
