@import './../../../../styles/colors';

.info-content {
    color: #003601;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    position: relative;

    .message {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }

    .actions {
        position: absolute;
        right: 0;
        margin-right: 5px;
        top: 50%;
        transform: translate(0%, -50%);


        button {
            color: #ffffff;
            font-size: 14px;
            padding: 2px 10px;
            border-radius: 3px;
            background-color: $error-color;
        }
    }
}