@import './../../../../styles/colors';

.no-data-message {
    padding: 10px;
    
    > div {
        display: flex;
        justify-content: center;
    }

    .icon {
        color: $divider-color;
    }

    .text {
        color: #9898b9;
        letter-spacing: -0.3px;
        font-size: 12px;
        font-weight: 600;
        margin: 10px 0px;
    }
}