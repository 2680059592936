$accent-color: #ff5252;
$accent-color-light: #ff5252;
$primary-text: #2c2e37;
$secondary-text: #757575;
$divider-color: #bdbdbd;
$background-color: #f3f4f5;
$secondary-button-hover: #e4e4e4;
// $border-color: #00000020;
$border-color: #e3ebf6;

// Color scheme
$primary-color: #5330b9;
$primary-color-dark: #401ab0;
$primary-color-light: #f6f6fb;
$primary-color-light-2: #edebf8;
$primary-color-light-3: #f9f9ff;
$white: #ffffff;

// New defined
$success-color: #00a051;
$success-dark-color: #038444;
$error-color: #c2303e;
$error-light-color: #c2303e1a;
$save-light-color : #4895ef;

// Text colors
$muted-text: #8181a8;
$main-light-background: #f4f4fb;
