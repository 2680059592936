.advanced-settings-wrapper {
  background-color: #fff;
  display: flex;
  justify-content: left;
  .settings-container {
    margin: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .setting-panel-container {
      max-width: 100%;
    }
  }
}
