@import './../../../styles/colors';

.offline-error {
    .box {
        display: inline-block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 600px;
        height: 300px;
        margin: auto;
        
        .content {
            width: 600px;

            .icon, .action {
                display: flex;
                justify-content: center;

                svg {
                    color: $secondary-text;
                }
            }

            .message {
                text-align: center;
                font-weight: 500;
                margin-top: 20px;
                color: $muted-text;

                h3 {
                    color: $error-color;
                    font-size: 24px;
                    font-weight: 600;
                }
            }

            .action {
                margin-top: 25px;

                button {
                    justify-content: center;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }
    }
}