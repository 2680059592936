@import "./../../../../styles/colors";

.widget-box-wrapper {
  background: linear-gradient(
    145deg,
    $primary-color-light,
    $primary-color-light-3
  );
  border: 0.5px solid #dae5ee;
  box-shadow: 1px 1px 10px $primary-color-light-2;
  border-radius: 5px;

  .heading {
    background: #ff99cc;
    padding: 5px 10px;
    min-height: 42px;
    background: linear-gradient(210deg, #e6e2f9, $primary-color-light-2);
    border-bottom: 1px solid $border-color;
    .main-text {
      display: flex;
      align-items: center;
      color: $primary-text;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: -0.5px;
      flex-grow: 1;
    }
  }
}
