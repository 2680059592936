@import './../../../../../styles/colors';

.add-filter-interface {
    width: 300px;
    padding: 5px;

    .control-container {
        margin-bottom: 20px;

        label {
            font-size: 14px;
            font-weight: 500;
        }

        &.actions {
            margin-bottom: 10px;
            align-items: center;
            justify-content: right;

            button {
                margin: 0px !important;
                min-width: auto !important;
                text-decoration: none;

                &:not(#apply-btn):hover {
                    text-decoration: underline;
                }

                &:not(#apply-btn) {
                    margin-right: 10px !important;
                }
            }

            #cancel-btn {
                color: $error-color;
            }
        }

        .control {
            .option-container {
                display: flex;

                .label {
                    flex-grow: 1;
                }

                .count {
                    font-size: 85%;
                    font-weight: 600;
                    color: $divider-color;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .error-message {
        font-size: 80%;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        padding: 5px 10px;
        border-radius: 5px;
        margin-bottom: 20px;
        font-weight: 400;
    }
}