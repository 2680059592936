@import './../../styles/colors';

.user-wrapper {
	display: flex;
	flex: 1 1;
	min-height: 100vh;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	.form-container {
		flex: 2;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.form-card {
		background: #fff;
		padding: 40px 50px;
		border-radius: 10px;
		min-width: 450px;
		max-width: 485px;
		box-shadow: 0px 12px 22px 0px rgba(0, 0, 0, 0.1);
		margin-bottom: 10px;
		border: 1px solid $border-color;
		input.form-control {
			height: calc(1.75rem + 12px);
			color: $primary-text;
		}
	}

	.logo {
		height: auto;
		display: block;
		margin: 0.5rem auto 1.5rem;
	}

	.input {
		background: $primary-color-light;
		margin-bottom: 5px;

		&:focus {
			border: 1px solid $muted-text;
		}
	}

	.icons {
		width: 20px;
		margin: 5px;
	}

	.rm {
		width: 6rem;
		margin: 0 auto;
		display: block;
	}
}
