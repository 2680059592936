.events-count-chart {
    
    .toolbar {
        display: flex;
        margin-bottom: 20px;

        .events-types-selector {
            min-width: 60%;
        }

        .interval-selector {
            flex-grow: 1;
            display: flex;
            justify-content: right;
        }
    }
}