@import './../../../../styles/colors';

.widget-expandable-list-item-wrapper {
    background-color: $primary-color-light-3;
    border-radius: 5px;
    margin-bottom: 3px;

    .preview {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 5px;
        padding: 10px;

        &:hover {
            background-color: $primary-color-light;
        }

        .user-icon-container {
            display: flex;
            width: 50px;
            height: 50px;
            align-items: center;
            justify-content: left;
            margin-right: 10px;

            .icon {
                display: flex;
                width: 75%;
                height: 75%;
                align-items: center;
                background: $primary-color-light-2;
                justify-content: center;
                border-radius: 5px;
                border: 1px solid #dcd9f0;

                svg {
                    height: 24px;
                    width: 24px;
                    color: $primary-color;
                }
            }
        }

        .primary-content {
            flex-grow: 1;

            .title {
                font-size: 14px;
                font-weight: 600;
            }
            
            .tip {
                padding-top: 2px;
                font-size: 12px;
                font-weight: 500;
                color: $muted-text;
                text-transform: uppercase;
                letter-spacing: 0.2px;
            }
        }

        .action-items {
            width: 250px;
            display: flex;
            justify-content: right;
        }
        
        .expander-icon {
            margin-left: 15px;
            cursor: pointer;

            svg {
                height: 32px;
                width: 32px;
                color: #a99ee7;
            }

            &:hover {
                svg {
                    color: $primary-color;
                }    
            }
        }
    }

    .details {
        padding: 0px 10px 10px 10px;

        .details-actions {
            margin-top: 10px;
            text-align: right;
        }
    }
}