.key-actions {
    display: flex;
    justify-content: end;

    button:not(:last-child) {
        margin-right: 10px;
    }

    button {
        svg {
            margin-right: 5px;
        }
    }
}