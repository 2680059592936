@import './../../../../styles/colors';

.widget-tabs-wrapper {
    .nav.nav-tabs {
        border: none;

        a {
            padding: 10px 10px 6px 10px;
            background: transparent;
            border-top: 0px;
            border-left: 0px;
            border-right: 0px;
            border-bottom: 3px solid;
            border-color: transparent;
            font-weight: 600;
            color: $muted-text;
            letter-spacing: -0.5px;

            &.active {
                border-color: $primary-color-dark !important;
                color: $primary-color;
            }
        }
    }
}