@import './../../../../../../../../styles/colors';

.invite-sent-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0px;

    .check-icon {
        margin-bottom: 10px;
        svg {
            height: 50px;
            width: 50px;
            color: $success-color;
        }
    }

    .text-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h5 {
            font-weight: 600;
        }
    }

    .action {
        margin-top: 10px;
    }
}