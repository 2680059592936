@import "./../../../../../../styles/colors";

.command-status-info-wrapper {
  border: 1px solid $border-color;
  margin: 12px 0;
  padding: 6px;
  border-radius: 4px;

  .preview {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    letter-spacing: -0.25px;
    align-items: center;
    justify-self: center;
    cursor: pointer;

    .icon-wrapper,
    .end-icon-wrapper {
      width: 32px;
    }

    .end-icon-wrapper {
      display: flex;
      justify-content: right;
      svg {
        color: $secondary-text;
      }
    }

    .primary-text {
      flex-grow: 1;
      font-weight: 600;
      color: $secondary-text;
    }
    .secondary-text {
      color: $muted-text;
      font-size: 90%;
    }
  }

  &.success {
    .preview {
      .icon-wrapper {
        svg {
          color: $success-color;
        }
      }
    }
  }

  &.failure {
    .preview {
      .icon-wrapper {
        svg {
          color: $accent-color;
        }
      }
    }
  }
  
  &.pending {
    .preview {
      .icon-wrapper {
        svg {
          color: $muted-text;
        }
      }
    }
  }

  .details-wrapper {
    background: $primary-color-light;
    padding: 8px;
    margin: 8px;
    border-radius: 4px;

    .description {
      font-size: 75%;
      color: $secondary-text;

      .desc-row {
        display: flex;
        padding: 2px 0;

        &:hover {
            background: $primary-color-light-2;
        }

        .column {
          &.key {
            font-weight: 600;
            width: 40%;
            max-width: 40%;
          }
        }
      }
    }
  }
}
