@import './../../../../../styles/colors';

.actions-wrapper {
    .info-message {
        letter-spacing: 0px;
        font-weight: 500;
        padding: 0px 5px;
        border-radius: 5px;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
    }

     &.accepted {
        background-color: #f8fff9a9;
        .info-message {
            color: $success-color;
            background-color: #d7f8dfa9;
        }
    }

    &.rejected {
        background-color: #fff8fba9;

        .info-message {
            color: $error-color;
            background-color: #f8d7daa9;
        }
    }
}