@import "./../../../../../../styles/colors";

.dot-container {
    position: relative;

    .circle {
        width: 10px;
        height: 10px;
        background-color: #00e572;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .ring {
        border: 3px solid #00e572;
        border-radius: 30px;
        height: 20px;
        width: 20px;
        position: absolute;
        left: 0px;
        top: 0px;
        animation: throb 1.5s ease-out;
        animation-iteration-count: infinite; 
        opacity: 0.1
    }

    @keyframes throb {
        0% {transform: scale(0.1, 0.1); opacity: 0.0;}
        50% {opacity: 1.0;}
        100% {transform: scale(1.2, 1.2); opacity: 0.0;}
    }
}