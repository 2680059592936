.log-trace-wrapper {
    line-height: 24px;
    margin-bottom: 5px;
    display: flex;

    & > div:first-child {
        margin-right: 10px;
    }

    .seperator {
        margin: 0px 3px;
    }

    .file {
        word-break: break-all;
    }

    .address {
        color: #fc929e;
        font-family: "FiraM";
    }

    .function {
        color: #79b6f2;
        font-family: "FiraM";
    }
}