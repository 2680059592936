@import './../../../../styles//colors';

.expandable-table {
    svg {
        color: $divider-color;

        &:hover {
            color: $primary-text;
        }
    }

    thead {
        position: sticky;
        top: 50px;

        th {
            font-weight: 500;
            background-color: #f4f4f4;
            border: none;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 12px;
            border-bottom: 1px solid #eeeeee;

            &:first-child {
                span {
                    padding-left: 5px;
                }
            }
        }
    }

    tbody {
        tr {
            &:hover {
                background-color: #edebf8;
            }

            td {
                padding: 0px;

                span {
                    padding: 5px 10px;
                    display: inline-block;
                }
            }

            &.category {
                td {
                    font-size: 14px;
                    letter-spacing: -0.5px;
                    color: $primary-text;
                    font-weight: 600;
                    background-color: $primary-color-light-3;
                    
                    &.indicate-heirarchy {
                        span {
                            padding-left: 15px;
                        }
                    }
                    
                    &.expanded {
                        
                    }
                }

                &:nth-child(odd) {
                    td {
                        // background-color: $main-light-background;
                    }
                }

                &:nth-child(even) {
                    td {
                        // background-color: $primary-color-light-3;
                    }
                }

                &:not(:first-child) {
                    td {
                        // border-top: 1px solid #f4f4f4;
                        //padding: 10px 0px 0px 0px;
                    }
                }
            }

            &:not(.category) {
                td {
                    &.expandable {
                        font-style: normal;
                        letter-spacing: 0.5px;
                        font-size: 13px;
                    }

                    &.non-expandable {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 32px;
                        letter-spacing: 0.5px;
                        color: #6b5d93;

                        &.indicate-heirarchy {
                            padding-left: 17px;
                            span {
                                border-left: 1px solid $divider-color;
                                display: flex;
                                align-items: center;
                                padding-left: 0px;
                                font-weight: 600;
                                margin-left: 10px;
                                color: #6b5d93;
                                &:before {
                                    content: ' ';
                                    width: 10px;
                                    height: 1px;
                                    background-color: $divider-color;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }       
    }
}