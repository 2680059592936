@import './../../../../../../../../styles/colors';

.log-type-tag {
    font-family: 'PoppinsM';
    background-color: #edebf8;
    padding: 3px 5px;
    width: 100%;
    text-align: center;
    border-radius: 3px;
    color: #ffffff;
    width: 45px;
    font-size: 9px;
    letter-spacing: -0.1px;
}