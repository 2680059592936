.save-dashboard-notice-wrapper {
    margin: 20px;

    & > div {
        .content {
            display: flex;
            align-items: center;

            .text {
                font-size: 14px;
                color: #837800;
            }

            .action {
                flex-grow: 1;
                display: flex;
                justify-content: right;

                button {
                    background-color: transparent;
                    color: #837800;
                    border: 1px solid #837800;
                    font-weight: 600;

                    &:hover {
                        background-color: #837800;
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
