@import './../../../../styles/colors';

.widget-sticky-toolbar {
    background-color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 97 !important;
    padding: 0px;

    .main-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-shadow: 0px 5px 20px #f4f4f4;
        padding: 10px;
    }

    .loader {
        width: 100%;
        position: relative;
        padding: 1px;
        background: transparent;
    }

    .loader:before {
        content: '';
        border: 1px solid transparent; 
        position: absolute;
        top: -4px; 
        right: -4px; 
        bottom: -4px; 
        left: -4px;
    }

    .loader .bar { 
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        left: 0;
        background: #5330b944; 
        width: 0;
        animation: loadingBar 850ms linear infinite;
      }
}