@import './../../../../styles/colors';

.simple-widget-box-wrapper {
    border: 1px solid $border-color;
    border-radius: 5px;
    height: 100%;

    .title {
        border-bottom: 1px solid $primary-color-light-2;
        display: flex;
        background: linear-gradient(210deg, $primary-color-light, $primary-color-light-3);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 5px;
        color: $muted-text;

        .icon {
            margin-right: 5px;
        }

        label {
            text-align: center;
            padding: 0px;
            margin: 0px;
            line-height: normal;
            font-size: 75%;
            font-weight: 600;
            letter-spacing: -0.2px;
        }
    }
}