@import './../../../styles/colors';

.tabs-container {
    height: 100%;

    .tabs-group {
        height: 100%;

        .tab{
            display: flex;
            height: 100%;
            align-items: center;
        }

        .menu-button{
            height: 100%;
            .dropdown-toggle{
                width: 10px !important;

                &::after{
                    content: "\22EE";
                    border: unset !important;
                }
            }
            .dropdown-menu {
                z-index: 1050;
                box-shadow: 1px 3px 6px #0000000d;
                .dropdown-item{
                    align-items: center;
                    display: flex;
                    padding: 5px 10px ;
                    font-size: 14px;
                    color: $primary-text;
                }
                .dropdown-item.active, .dropdown-item:active {
                    color: black;
                    text-decoration: none;
                    background-color: transparent !important;
                }
            }
        }

        .button-wrapper{
            &.selected {
                button {
                    border-color: $primary-color-dark !important;
                    font-weight: 600;
                    border-bottom: 3px solid;
                    border-color: $muted-text;
                    color: $primary-text;
                }
            }
        }
        
        
        button {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            border: 0px;
            color: $muted-text;
            font-size: 14px;
            letter-spacing: 0px;
            border-bottom: 3px solid;
            border-color: transparent;
            border-radius: 0px;
            font-weight: 600;
            width: 160px;

            svg {
                margin-right: 5px;
            }

            &:hover, &:focus {
                &:not(:disabled) {
                    border-color: $muted-text;
                }
            }

            &.selected {
                border-color: $primary-color-dark !important;
                color: $primary-color-dark !important;
            }

            &:hover, &.selected {
                &:not(:disabled) {
                    color: $primary-text;
                }
            }

            &:active, &:focus {
                box-shadow: none;
                background-color: transparent !important;
                color: $primary-text !important;
            }

            &:disabled {
                cursor: not-allowed;
            }
        }
    }
}