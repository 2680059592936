@import './../../../../../../styles/colors';

.node-info-card {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #e2dff3;
    background: $primary-color-dark;
    background: linear-gradient(305deg, #f4f4fb99, $primary-color-dark);
    box-shadow: 1px 1px 5px 0px #e3ebf6;
    min-height: 150px;

    .info-container {
        position: relative;
        display: flex;
        flex-direction: column;

        .node-chip .chip-id,
        .node-id h5,
        .project-details .name,
        .project-details .version {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &.regular {
        .node-chip {
            background-image: linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0.1)), url('./../../../../../../assets/esp-chip.png');
            background-position: center;
            background-repeat: no-repeat;
            width: 120px;
            height: 120px;
            position: absolute;
            right: 0;
            opacity: 1;
        
            .indicator {
                position: absolute;
                top: 13px;
                left: 13px;
            }
        
            .chip-id {
                position: absolute;
                bottom: 20px;
                color: #b4b4b4;
                left: 50%;
                font-size: 12px;
                font-weight: 600;
                transform: translateX(-50%); 
                letter-spacing: 0.5px;
                text-shadow: 1px 1px #000000;
                max-width: 75%;
                cursor: default;
            }
        }
    }

    &.full-width {
        background: linear-gradient(305deg, #8181f699, $primary-color-dark);

        min-height: auto;
        .info-container {
            flex-direction: row;
            
            .node-chip {
                display: none;
            }

            .node-id {
                label {
                    padding-left: 10px;
                }
            }

            .data-row {
                display: flex;
                flex-direction: row;
                margin: 0px 20px 0px 0px;

                &:not(.status) {
                    align-items: center;
                }

                label {
                    line-height: normal;
                    margin-right: 10px;
                    margin-bottom: 0px;
                }

                h6 {
                    margin: 0;
                }

                .indicator {
                    left: 0px;
                    margin-top: -2px;
                }

                .details {
                    align-items: center;
                    > div {
                        line-height: normal;
                        overflow: visible;
                    }

                    .name {
                        max-width: none;
                    }

                    .seperator {
                        max-width: 24px;
                    }
                }
            }
        }
    }


.node-id,
.project-details,
.node-status {
    max-width: 60%;
}


label {
    color: #9e91e9;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    margin-bottom: 2px;
    letter-spacing: 0.2px;
    line-height: 28px;
}

.node-id {
    letter-spacing: -0.5px;

    h6 {
        font-size: 1vw;
        font-weight: 600;
        color: #f0effa;
        text-shadow: 2px 1px $primary-color-dark;
    }
}

    .project-details {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        font-size: 0.75vw;
        font-weight: 600;
        color: #f0effa;

        .details {
            display: flex;
            text-shadow: 1px 1px $primary-color-dark;

            .name {
                max-width: 70%;
            }

            .version {
                flex-grow: 1;
                font-weight: 600;
            }

            .seperator {
                max-width: 10%;
                &::after {
                    content: "";
                    width: 5px;
                    height: 10px;
                    border-right: 1px solid $divider-color;
                    margin: 0px 10px;
                }
            }
        }
    }
}
