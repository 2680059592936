@import './../../../../../styles/colors';

.menu-content {
    padding: 5px 10px 10px 10px;

    .calendar {
        margin-bottom: 20px;

        .react-calendar__viewContainer {
            > div:first-child {
                margin-right: 50px;
            }
        }
    }

    .time-range-inputs {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;

        .date-time-contianer {
            label {
                font-size: 12px;
                margin-right: 10px;
                font-weight: 600;
                letter-spacing: -0.2px;
                color: $primary-text;
            }

            .date-time {

                > * {
                    margin-right: 5px;
                }
                
                input.date,
                input.rc-time-picker-panel-input {
                    margin-right: 10px;
                    border-radius: 0px !important;
                    font-size: 14px;
                }

                .advanced-time-selector {
                    input {
                        width: 125px !important;
                        border-radius: 0px !important;
                        font-size: 14px;
                    }

                    .rc-time-picker-clear {
                        padding-top: 3px;
                    }
                }
            }
        }
    }

    .footer {
        display: flex;

        .quick-links {
            display: flex;

            a {
                align-items: center;
                display: flex;
                padding-right: 20px;
                text-decoration: none;
                color: #5330b9;

                &:hover {
                    text-decoration: underline;
                }
            }

            .selected {
                color: $primary-color-dark;
                text-decoration: underline;
            }
        }

        div:last-child {
            flex-grow: 1;
            align-items: center;

            button {
                margin-left: auto;
            }
        }

        .quick-links {
            button {
                font-size: 12px;
                font-weight: 500;
            }
        }

        .actions,
        .quick-links {

            align-items: center;
            justify-content: right;

            button {
                margin: 0px !important;
                min-width: auto !important;
                text-decoration: none;

                &:not(#apply-btn):hover {
                    text-decoration: underline;
                }

                &:not(#apply-btn) {
                    margin-right: 10px !important;
                }
            }

            #cancel-btn {
                color: $error-color;
            }
        }
    }

    .error-message {
        font-size: 80%;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        padding: 5px 10px;
        border-radius: 5px;
        margin-bottom: 20px;
        font-weight: 400;
    }

    &.single-date {
        min-width: 400px;

        .calendar {
            margin: 0;
        }

        .react-calendar__viewContainer {
            > div:first-child {
                margin-right: 0px;
            }
        }

        .time-range-inputs {
            flex-direction: row;
            margin-bottom: 0px;
            margin-top: 20px;

            .date-time-contianer {
                .advanced-time-selector {
                    input {
                        width: 170px !important;
                    }

                    .rc-time-picker-clear {
                        padding-top: 3px;
                    }
                }

                &:first-child {
                    margin-bottom: 20px;
                }
            }
        }

        .footer {
            width: 100%;
        }
    }
}