@import './colors';

.text-link {
	color: $primary-color !important;
	font-size: 14px;
}

.text-error {
	color: $error-color;
	font-size: 14px;
	margin-bottom: 0;
}

.text-muted {
	color: $muted-text !important;
	font-size: 14px;
	font-weight: 400;
}

.text-secondary {
	color: $primary-text !important;
	background-color: $primary-color-light;
	font-size: 14px;
	padding: 5px 10px;
}

.table-link {
	color: $primary-color !important;
	cursor: pointer;

	&:hover {
		color: $primary-color !important;
		text-decoration: underline !important;
	}
}

.page-title {
	font-size: 22px;
	text-transform: capitalize;
	font-weight: 500;
	margin-bottom: 0;
	margin-right: 8px;
	color: $primary-text;
}

.modal-page-title {
	font-size: 18px;
	text-transform: capitalize;
	font-weight: bold;
	margin-bottom: 0;
	margin-right: 8px;
}

.page-title-settings {
	font-size: 18px;
	color: $primary-text;
}

.back-link {
	margin-bottom: 0;
	color: $primary-color;

	&:hover {
		color: $primary-color;
	}
}

.idp-options {
	display: inline;
	text-transform: lowercase;
	margin-right: 16px;
}

.remark-text {
	color: #c52f13;
	background: #ef53500d;
	cursor: default;
	padding: 4px 8px;
	line-height: 18px;
	border: 1px solid transparent;
	border-radius: 2em;
	font-size: 12px;
}

.font-12 {
	font-size: 12px;
}
