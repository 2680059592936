@import './../../../../../../../styles/colors';    

.actions-container {
    display: flex;
    button {
        background-color: transparent;
        outline: none;
        border: none;
        font-size: 12px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
        color: $primary-color;
        border-radius: 5px;
        padding: 0px;
        margin-right: 5px;

        &:hover:not(:disabled) {
            text-decoration: underline;
        }

        svg {
            
        }

        label {
            margin: 0;
        }

        &:disabled {
            color: $secondary-text;
        }
    }
}

.select-dropdown {
    flex-grow: 1;
    margin-left: 10px;

    .multi-select-dd {
        font-size: 12px;

        &> div {
            min-height: 35px !important;
            border-color: $primary-color-light-2;
            box-shadow: none !important;

            &> div:nth-child(2) {
                display: none;
            }
        }
    }
}