@import './../../../../styles/colors';

.basic-light-card-wrapper {
    border-radius: 10px;
    border: 1px solid #ebeaf1;
    background: linear-gradient(210deg, $primary-color-light-2, $primary-color-light-3);
    box-shadow: 1px 1px 5px 0px #e3ebf6;

    .card-content {
        .actions {
            border-top: 1px solid $border-color;
            background: #edebf8;
            display: flex;
            justify-content: right;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            button {
                border: none;
                background: transparent;
                padding: 10px;
                color: $primary-color;
                font-weight: 600;
                font-size: 14px;

                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
}