@import './../../../../../../../styles/colors';

.confirm-access-account-wrapper {
    margin: 10px 16px;
    font-size: 12px;

    .display-data {
        border-radius: 5px;
        border: 1px solid $primary-color-light-2;
        padding: 10px;

        .detail-row {
            display: flex;
            font-size: 12px;
            padding: 5px;
            font-weight: 600;

            &:hover {
                background: $primary-color-light-2;
            }

            .key {
                width: 200px;
                color: $muted-text;
                text-align: right;
                margin-right: 20px;
            }
            
            .value {
                color: $secondary-text;
            }
        }
    }
}