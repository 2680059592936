@import './../../styles/colors';

.text-field-with-icon-button-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: $white;
  border-radius: 0.25rem;

  &:focus-within {
    border: 1px solid $primary-color;
  }

  .text-input {
    padding: 0 12px;
    width: 100%;
  }

  input {
    border: 0;
    font-size: 14px;
    color: #2c2e37;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }

  button {
    padding: 7px 12px;
    height: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    background-color: $primary-color;
  }
}
