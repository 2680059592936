@import './../../styles/colors';

.select-dropdown-list {
	position: absolute;
	top: 100%;
	background-color: $white;
	list-style-type: none;
	padding-left: 0;
	width: 100%;
	max-height: 240px;
	overflow: auto;
	z-index: 20;
	border: 1px solid $border-color;

	li {
		background-color: $white;
		padding: 4px 16px;
		margin: 4px 0;
		font-size: 14px;

		&:hover {
			background-color: $background-color;
			cursor: pointer;
		}
	}
}
