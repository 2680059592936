@import './../../../../styles/colors';

.date-range-picker-input {
    margin-left: auto;

    
    .date-range-dropdown {
        button.dropdown-toggle.btn.btn-primary {
            background-color: transparent;
            cursor: pointer;
            color: $primary-color;
            border: 1px solid transparent;
            box-shadow: none;
            font-size: 12px;
            font-weight: 600;
            padding: 0px;
            border-radius: 5px;
            letter-spacing: -0.2px;
            opacity: 0.9;

            &:hover {
                opacity: 1;
                background-color: transparent;
                color: $primary-color-dark;
            }

            &, .date-range-text-container {
                display: flex;
                align-items: center;
                .interval-label{
                    background: #d3c4fd;
                    padding: 2px 5px;
                    border-radius: 3px;
                }
            }
            
            .date-range-text-container {
                .start-date, .end-date {
                    padding: 5px 5px;
                    margin: 1px;
                    background-color: transparent;
                }

                .seperator {
                    font-size: 21px;
                    margin: 0px 2px;
                    color: $muted-text;
                    font-weight: 600;
                }
            }

            svg {
                width: 20px;
            }
            
            span {
                padding-right: 5px;
            }

            &::after {
                display: none;
            }

            &:hover, 
            &:active, 
            &:focus {
                @extend button.dropdown-toggle.btn.btn-primary
            }
        }

        .menu-content.dropdown-menu {
            box-shadow: 1px 3px 6px #0000000d;
            border-radius: 0px;
            border: 1px solid $border-color;
        }

    }
}