@import './colors';

.primary-button {
	background-color: $primary-color-light-2;
	color: $primary-color;
	font-size: 14px;
	display: flex;
	align-items: center;
	padding: 6px 16px;
	border: 1px solid $primary-color;
	&:hover:enabled {
		color: $primary-color-light !important;
		background-color: $primary-color;
		border-color: $primary-color;
	}

	&:disabled {
		color: $secondary-text;
		background-color: $white;
		border-color: $secondary-text;
		cursor: not-allowed;
		border: 1px solid;
		height: 35px;
	}

	&:focus {
		box-shadow: none;
	}

	svg {
		margin-right: 5px;
	}
}

.error-button {
	background-color: $error-light-color;
	color: $error-color;
	border: 1px solid $error-color;
	font-size: 14px;

	&:hover:enabled {
		background: $error-color;
		color: $white;
		border: 1px solid $error-color;
	}
}

.primary-button-small {
	font-size: 12px;
	border: 0;
	height: 32px;
}

.error-button-small {
	background-color: $error-light-color;
	color: $error-color;
	max-height: 35px;

	&:hover:enabled {
		background: $error-color;
		color: $white;
	}

	svg {
		margin-right: 5px;
	}
}

.icon-button {
	border: none;
	outline: none;
	background: none;
	padding: 0;

	&:disabled{
		cursor: not-allowed;
	}
}

.success-button {
	background-color: $success-color;
	color: $white;
	font-size: 14px;
	display: flex;
	align-items: center;
	padding: 6px 16px;
	border: 1px solid $success-dark-color;

	&:hover {
		color: $primary-color-light;
		background-color: $success-dark-color;
	}

	&:focus {
		box-shadow: none;
	}
}

.primary-dark-button {
	background-color: $primary-color;
	color: $white;

	&:hover {
		background: $primary-color-dark;
		color: $white;
	}

	&:disabled {
		background: $primary-color-dark;
		color: $white;
		cursor: not-allowed;
		opacity: 0.7;
	}
}

.error-dark-button {
	background-color: $error-color;
	color: $error-light-color;

	&:disabled {
		background: $error-color;
		color: $error-light-color;
		cursor: not-allowed;
		opacity: 1;
	}

	&:hover:enabled {
		background: $error-color;
		color: $error-light-color;
		border: 1px solid $error-color;
	}
}

.link-button {
	background-color: transparent;
	color: $primary-text;
	font-size: 14px;
	text-decoration: underline;
}

.input,
.advanced-time-selector input,
.rc-time-picker-panel-input {
	border: 1px solid $border-color;
	padding: 6px 12px;
	font-size: 14px;
	color: $primary-text;
	border-radius: 0.25rem;
	width: 100%;
	height: auto;

	&:focus {
		border: 1px solid $muted-text;
		outline: 0;
		box-shadow: none;
	}
}

.label {
	font-size: 14px;
}

.custom-select {
	width: auto;
	margin-left: 8px;
	font-size: 14px;
	border-color: $border-color;

	&:focus {
		box-shadow: none;
	}
}

.image-search {
	.input {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.custom-select {
		width: auto;
		margin-left: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;

		&:focus {
			border-color: $border-color;
		}
	}
}

.social-btn {
	margin: 1rem 0;
	display: flex;
	align-items: center;
	text-align: center;
	font-size: 16px;
	border: 1px solid $border-color;
	box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.15);
	&:hover {
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
	}
	span {
		width: 100%;
	}
}

.social-icon {
	width: auto;
	height: 30px;
}

.radio-label {
	position: relative;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	font-size: 14px;
	display: flex;
	align-items: center;
	margin-right: 1rem;
}

input[type='radio'] {
	opacity: 0;
	z-index: 9999;
}
.radio-overlay {
	display: inline-block;
	position: relative;
	margin-left: -12px;
	height: 13px;
	width: 13px;
	border-radius: 50%;
	margin-right: 4px;
	background-color: $white;
	border: 1px solid #bbb;
}
input[type='radio']:checked + .radio-overlay {
	background-color: $primary-color;
}

.force-push {
	font-size: 14px;
	margin-top: 1rem;
}

.link-btn {
	color: $primary-color;
	cursor: pointer;
	text-decoration: underline;
	padding: 0;
	display: block;
	font-size: 14px;
	margin-bottom: 1rem;

	&:hover {
		color: $primary-color-dark;
		text-decoration: underline;
	}

	&:focus {
		box-shadow: none;
	}
}

.btn:focus {
	box-shadow: none;
}

.show-hide-psw {
	position: absolute;
	right: 0;
	top: 0;
}

.email-text {
	min-height: 200px;
	width: 100%;
}

.nav-tabs {
	border-bottom: 1px solid $border-color;

	.nav-link {
		font-size: 14px;
		background: $primary-color-light;
		border-right: 1px solid $border-color;
	}

	.nav-link.active {
		color: $primary-color;
		border-color: $border-color $border-color #fff !important;
	}
	.nav-item {
		margin-bottom: 0 !important;
	}
}

.primary-icon-btn {
	cursor: pointer;
	background: #5330b91a;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	margin: 0 8px;
	padding: 4px 8px;
	font-size: 14px;
	color: $primary-color;
	border-radius: 4px;
}

// -------------- DELETE BUTTON ------------------
.delete-icon-btn{
	cursor: pointer;
	background-color: $error-light-color;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	margin: 0 8px;
	padding: 4px 8px;
	font-size: 14px;
	color: $error-color;
	border-radius: 4px;

	&:disabled{
		cursor: not-allowed;
		opacity: 0.7;
	}
}
// -------------- COMMON CUSTOM BUTTON ------------------
.custom-btn {
	border-color: transparent;
}

// -------------- REFRESH BUTTON ------------------
.refresh-icon {
	transform: rotateY(180deg);
}

// -------------- SEARCH INPUT COMPONENT ------------------
.search-input-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid rgba(0, 0, 0, 0.1);
	background-color: $white;
	border-radius: 0.25rem;

	&:focus-within {
		border: 1px solid $primary-color;
	}

	.search-input {
		padding: 4px 12px;
	}

	input {
		border: 0;
		font-size: 14px;
		color: #2c2e37;

		&:focus {
			outline: 0;
		}
	}

	button {
		padding: 7px 12px;
		height: 100%;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0.2rem;
		border-top-right-radius: 0.2rem;
		background-color: $primary-color;
	}
}

.has-error{
	input {
		border-color: $accent-color !important;
	}
}

.error-lable{
	color: $error-color ;
	position: absolute;
}