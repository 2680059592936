@import './../../../../styles/colors';

.interval-selector-tags {
    padding: 0 !important;
    display: flex;
    align-items: center;
    button {
        border: none;
        background-color: transparent;
        outline: none;
        padding: 0px;

        .tag {
            font-weight: 400 !important;
            font-size: 12px !important;
            border: 1px solid transparent;
            background-color: transparent;
            color: $primary-color-dark;
        }

        .tag.active {
            background:  $primary-color-light-2;
        }
    }
}