@import './../../styles/colors';

table {
	border: 1px solid $border-color;
	width: 100%;
	height: 100%;
}

th,
td {
	padding: 9px 14px;
	font-size: 14px;
	overflow: auto;
	max-width: 12vw;
	border-bottom: 1px solid $border-color;
}

th {
	font-size: 14px;
	background-color: $primary-color-light;
	font-weight: 500;
}

tr {
	td {
		a {
			color: $primary-color;
		}
	}
}

.table-form-container{
	border-top:  1px solid $border-color;
	border-bottom:  1px solid $border-color;
	background-color: $primary-color-light;
	td{
			padding-bottom: 20px;
	}
	
}
// tr:nth-child(even) {
// 	background-color: $primary-color-light;
// }
