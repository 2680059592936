.toggle-token-display {
    button {
        text-decoration: none;

        :hover {
            text-decoration: underline;
        }
    }

    .token-text-container {
        .text {
            word-break: break-all;
            background-color: #f5f5f5;
            padding: 10px;
            margin-bottom: 10px;
            line-height: 24px;
            font-size: 13px;
        }

        button {
            margin-bottom: 10px;
            svg {
                margin-right: 5px;
            }
        }
    }
}