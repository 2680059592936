@import './../../../../../styles/colors';

.timeout-configuration-wrapper {
  padding: 10px;
  background-color: #fff;

  .config-form {
    display: flex;
    margin-top: 15px;
    label {
        font-size: 14px;
        font-weight: 600;
        color: $secondary-text;
        display: flex;
        align-items: center;
        flex-grow: 1;
        margin: 0;
    }
  }
}
