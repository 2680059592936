@import "./../../../../../styles/colors";

.nodes-list {

    background-color: #fff !important;
    box-shadow: 1px 1px 10px $primary-color-light;
    border-radius: 5px;
    border: 1px solid transparent;

    .item-row {
        padding: 0px;
        font-size: 14px;

        &.header {
            font-weight: 500;
            background-color: #f4f4f4;
            border: none;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 12px;

            .box > div {
                cursor: default;
            }
        }

        .box {
            padding: 15px;

            > div {
                display: flex;
            }

            .icon {
                width: 5%;
                align-items: center;
            }

            .text {
                width: 60%;
            }

            .count {
                flex-grow: 1;
                justify-content: right;
            }

            .count {
                width: 10%;
                justify-content: right;
            }

            .actions {
                justify-content: right;
                width: 25%;
            }
        }
    }
}