@import './../../../../../../styles/colors';

.node-tags-card-wrapper {
    padding: 15px;
    width: 100%;

    h6 {
        font-size: 16px;
        font-weight: 600;
        color: #807aa4;
        letter-spacing: -0.5px;
        margin-bottom: 0px;
        line-height: 36px;
    }
    
}