.register-values-wrapper {
    margin-top: 10px;
    h6 {
        margin: 0;
        background-color: #f9f9fe;
        color: #282c34;
        font-family: "FiraM";
        display: inline-block;
        font-size: 100%;
    }

    .values {
        .value-col {
            padding: 0;

            .title {
                font-family: "FiraM";
                color: #79b6f2;
            }

            .seperator {
                margin: 0px 3px;
            }
        }
    }
}