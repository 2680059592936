@import './../../styles/colors';

.custom-tooltip-wrapper {
    .custom-tooltip {
        transition: none !important;
        background: linear-gradient(30deg, #3a2c61, #5d47a0) !important;
        font-size: 12px !important;
        border-radius: 5px !important;
        color: $primary-color-light-3;
        opacity: 1 !important;
        font-family: "PoppinsM";
        z-index: 1021;
        font-weight: 400 !important;
    }
}