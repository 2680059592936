@import '../../../styles/colors';

.connector-status-container{
    background-color: $primary-color-light;
    
    tbody{
        tr {
            td:first-child{
                border-right: #ccc solid 1px;
                font-weight: bold;
            }

            td{
                font-size: 12.6px;
                padding: 5px 10px;
            }
        }
        
    }
}