@import './../../../../../../styles/colors';
.stats {
    display: flex;

    & > div {
        width: 50%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:first-child {
            border-right: 1px solid $border-color;
        }

        & > div {
            display: flex;
        }
    }

    label {
        font-size: 16px;
        font-weight: 600;
        color: #807aa4;
        letter-spacing: -0.5px;
        margin-bottom: 0px;
        line-height: 36px;
    }

    .total-count {
        h1 {
            color: $primary-text;
            margin: 0px;
            font-family: "PoppinsM";
        }

        .guage {
            color: #24a524;
        }
    }

    .items {
        display: flex;
        flex-direction: column;

        .item {
            display: flex;
            align-items: center;

            &.hide {
                display: none;
            }

            &:not(:last-child) {
                margin-bottom: 15px;
            }

            svg {
                margin-right: 10px;
            }

            label {
                font-size: 14px;
                font-weight: 400;
            }

            .count {
                font-size: 14px;
                flex-grow: 1;
                text-align: right;
                font-weight: 600;
                color: $primary-text;
            }
        }
    }
}