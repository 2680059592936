@import './../../../styles/colors';

.advanced-insights-navbar {
    background: linear-gradient(270deg, #f2f2fd, #ffffff);
    margin-bottom: 15px;
    padding-bottom: 0px;
    padding-top: 0px;
    opacity: 1;
    border-bottom: 0.5px solid #e1def5;
    height: 50px;
    z-index: 99 !important;

    .main-content {
        width: 100%;
        height: 100%;

        .heading-container {
            height: 100%;

            h1 {
                font-size: 18px;
                font-weight: 600;
                color: $primary-text;
                letter-spacing: -0.75px;
                margin: 0px;
            }
        }
    }

    .menu-button{
        margin-left: 20px;
        button.dropdown-toggle.btn.btn-primary{
            background-color: transparent;
            cursor: pointer;
            color: $primary-color;
            border: 1px solid transparent;
            box-shadow: none;
            font-size: 12px;
            font-weight: 600;
            padding: 0px;
            border-radius: 5px;
            letter-spacing: -0.2px;
            opacity: 0.9;

            &:hover {
                opacity: 1;
                background-color: transparent;
                color: $primary-color-dark;
            }

            &::after{
                display: none;
            }
        }
    }

    .dropdown-menu {
        border: 0.5px solid #c5c2d5;
        border-radius: 5px;
        box-shadow: 1px 1px 5px 0px #c5c2d5;
        padding: 0px;
        z-index: 90;

        .menu-label{
            border-bottom: 0.5px solid #c5c2d5 !important;
			background: linear-gradient(145deg, $primary-color-light-2, $primary-color-light-3);
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
            font-weight: 600;
			color: $secondary-text;
            cursor: default;
            &:hover{
                color:  $secondary-text !important;
            }
        }

        .dropdown-item {
            padding: 10px;
            font-size: 14px;
            border: 0px;
            color: $secondary-text;

            svg {
                margin-right: 10px;
                color: $primary-color;
            }

            &:hover {
                background-color: $primary-color;
                color: white;
                svg {
                     color: #fff;
                }
            }
        }
    }

}