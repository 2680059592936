.logo-spinner {
    background: url(../../../assets/insights-spinner.gif) no-repeat 50% 50% transparent;

    &.inline {
        width: 100%;
        height: 100px;
        background-size: 36px;
        opacity: 0.8;
    }

    &.full-width {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
        background-size: 48px;
        background-color: white;
        opacity: 0.6;
    }

    &.show {
        display: block;
    }
    
    &.hide {
        display: none;
    }
}

