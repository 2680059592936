@import './../../../../styles/colors';

button.link-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $primary-color;
    text-decoration: none;
    padding: 5px;
    background: transparent;
    border: none;
    font-weight: 600;


    &.default {
        font-size: 14px;
        letter-spacing: -0.5px;
    }
    
    &.small {
        font-size: 12px;
        letter-spacing: -0.2px;
    }
    
    &.large {
        font-size: 16px;
        letter-spacing: -0.7px;
    }

    &.with-light-background, &:focus, &:active, &:hover {   
        background-color: #5230b911 !important;
        color: $primary-color-dark !important;
        border: none !important;
    }

    &.with-light-border {
        border: 1px solid #5230b911 !important;
    }

    &:disabled {
        &, &:hover {
            background: transparent !important;
            color: $muted-text !important;
            cursor: not-allowed;
        }

        .button-icon-container {
            color: $muted-text !important;
        }
    }

    .button-icon-container {
        display: flex;
        align-items: center;

        svg {
            height: 18px;
            width: 18px;
            margin-right: 1px;
        }

        &.default {
            color: $primary-color-dark;
        }
        
        &.error {
            color: $error-color;
        }
        
        &.success {
            color: $success-color;
        }
    }
}