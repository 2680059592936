.credit-units-table-wrapper {
    padding: 10px;
    background: linear-gradient(210deg, #bfddfc67, #bfdffc);
    border: 1px solid #bfdffc;
    border-radius: 5px;

    .headline {
        font-size: 90%;
        font-weight: 500;
        color: #5f707f;
        line-height: 24px;
        letter-spacing: -0.1px;
        border-bottom: 1px solid #bfdffc;
        padding-bottom: 5px;

        .highlight {
            font-weight: 600;
        }
    }

    table {
        tr {
            &:not(:last-child) {
                border-bottom: 1px solid #bfdffc;
            }
            &:hover {
                background-color: #bfdffc;
            }
            td {
                font-size: 75%;
                padding: 5px;
                color: #405668;
    
                &.event-key {
                    text-align: right;
                    padding-right: 10px;
                    border-right: 1px solid #bfdffc;
                    font-weight: 600;
                }
    
                &.units {
                    color: #405668;
                    .number {
                        font-size: 16px;
                        font-family: PoppinsM;
                        letter-spacing: -1px;
                        color: #405668;
                        padding-right: 5px;
                    }
                }
            }
        }
    }
}