@import './../../../../styles/colors';

.widget-list-item-wrapper {
    display: flex;
    align-items: stretch;
    background-color: $primary-color-light-3;
    margin-bottom: 3px;
    padding: 10px;
    border-radius: 5px;

    &:hover {
        background-color: $primary-color-light;
    }

    .avatar {
        width: 48px;
        display: flex;
        align-items: flex-start;
        justify-content: left;

        svg {
            height: 32px;
            width: 32px;
            color: $divider-color;
        }
    }

    .content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        min-width: 0;
        font-size: 12px;
        letter-spacing: -0.5px;

        .header {
            display: flex;
            width: 100%;
            margin-bottom: 6px;

            .title {
                font-size: 13px;
                font-weight: 600;
                width: 80%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $primary-text;
            }

            .tip {
                display: flex;
                font-weight: 500;
                justify-content: right;
                flex-grow: 1;
                color: $muted-text;
            }
        }
        .body {
            display: flex;
            border-radius: 5px;
            color: $muted-text;
        }
        .footer {
            display: flex;
            justify-content: right;
            margin-top: 5px;

            .info-message {
                letter-spacing: 0px;
                font-weight: 500;
                padding: 0px 5px;
                border-radius: 5px;
                font-weight: bold;
            }
        }
    }

    &.accepted {
        background-color: #f8fff9a9;
        .info-message {
            color: $success-color;
            background-color: #d7f8dfa9;
        }
    }

    &.rejected {
        background-color: #fff8fba9;

        .info-message {
            color: $error-color;
            background-color: #f8d7daa9;
        }
    }
}