@import './../../../../../styles/colors';

.events-table {

    .event-row {
        display: flex;
        border: none;
        padding: 15px;
        align-items: center;
        cursor: pointer;
        border-radius: 5px;
        margin-bottom: 1px;

        &.highlight {
            background-color: #faf5ea;
        }

        .icon {
            height: 26px;
        }

        .label {
            color: $primary-text;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
        }

        .value {
            flex-grow: 1;
            text-align: right;
            font-weight: 500;
            font-size: 15px;
            color: $secondary-text;
        }

        &:hover {
            background-color: $primary-color-light-2;

            .label {
                color: $primary-color-dark;
                text-decoration: underline;
            }
        }
    }
}