
@keyframes blink {
	0% { opacity: 0; }
	50% { opacity: 1; }
	100% { opacity: 0; }
}

.dot {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
	cursor: pointer;

    .popover {
        border-radius: 5px;
        font-size: 90%;
        border: 0px;
        box-shadow: 2px 1px 11px 1px rgba(105, 105, 105, 0.86);

        .popover-header {
            font-size: 12px;
            background: none;
            border-bottom: 1px solid #dddddd;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            span {
                font-weight: bold;
                text-transform: capitalize;
            }
        }

        .popover-header, .popover-body {
            padding: 7px;
        }
    }
}

.dot.loading {
	animation: blink 1s infinite;
}

.dot.online {
	background-color: #31a24c;

    .popover-header span {
        color: #31a24c;
    }
}

.dot.offline {
	background-color: #fd4a4a;

    .popover-header span {
        color: #fd4a4a;
    }
}

.dot.unknown {
	background-color: #d8d8d8;
}

.dot._0 {
	background-color: #aab2c8;
}

.dot._1 {
    background-color: #82b1ff;
}

.dot._2 {
    background-color: #5330b9;
}
