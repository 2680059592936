@import './../../styles/colors';

.sidebar {
	width: 240px;
	position: fixed;
    background: #ffffff;
	max-height: 100vh !important;
	height: 100vh !important;
	z-index: 1;

	&.collapsed {
		width: 40px;
		z-index: 99;

		.child{
			display: none !important;
		}

		.list-group {
			.brand-logo {
				img {
					width: 30px;
				}
			}

			.list-group-item {
				padding-left: 0;
				padding-right: 0;
				justify-content: center;
				p {
					display: none;
				}
				span, .icon {
					margin-right: 0 !important;
				}
				button.dropdown-toggle {
					box-shadow: none !important;
					> div {
						justify-content: center;
						display: flex;
					}
				}
			}
		}

		.dropdown-menu {
			left: 20px !important;
		}
	}

	.list-group {
		background: #ffffff;
		.brand-logo {
			border-bottom: 0.5px solid #e1def5;
			z-index: 1;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 175px;
				margin: 0;
			}
		}

		.dropdown-child-menu{
			.list-group-item {
				padding: 0px;
				p {
					margin-left: 30px;
					border-left: 1px solid #edebf8;
					padding: 7px 20px;
					font-size: 13px !important;

					& + .icon {
						flex-grow: 1;
						justify-content: right;
						padding-right: 10px;
					}
				}
			}
		}

		.menu-item {
			background: transparent;
			transition: 0.5s;
			&.active, &:hover {
				.child{
					display: block;
				}
				text-decoration: none;
				.list-group-item { 
					background-color: #edebf8;
				}	
			}
			&.active {
				.list-group-item {
					.icon,
					p {
						color: $primary-color;
					}
				}
			}

			
			.list-group-item {
				border: none;
				display: flex;
				border-radius: 0px;
				flex-grow: 1;

				&.active {
					background: transparent;
				}

				.icon {
					color: $primary-text;
					display: flex;
					align-items: center;
					margin-right: 10px;
					svg {
						width: 21px;
					}
				}

				.expand-icon{
					svg{
						color: $muted-text;
					}
				}
				p {
					line-height: 24px;
					color: $secondary-text;
					font-size: 14px;
					font-weight: 600;
					letter-spacing: -0.5px;
    				text-overflow: ellipsis;
    				overflow: hidden;
				}

				button, button:active, button:focus {
					background: none;
					padding: 0;
					border: none;
					box-shadow: none !important;

					&::after {
						display: none;
					}
				}

				&.collapse-togler {
					border-top: 0.5px solid #e3e2e2;
					background: #f4f4f4;

					button {
						display: flex;
						span {
							margin-right: 8px;
						}
						p {
							font-weight: 400;
							margin-bottom: 0;
							margin-left: 8px;
						}
					}
				}

				.dropdown-menu {
					border: 0.5px solid #c5c2d5;
					border-radius: 5px;
					box-shadow: 1px 1px 5px 0px #c5c2d5;
					padding: 0px;
					z-index: 90;

					.dropdown-item {
						padding: 0px;
						font-size: 14px;
						border: 0px;

						&:not(.user-info) {
							&:hover {
								background-color: $primary-color;

								.menu-item {
									svg, & {
										color: #fff;
									}
								}
							}

							.menu-item {
								color: $secondary-text;
								display: block;
								padding: 10px;
								svg {
									margin-right: 10px;
									color: $primary-color;
								}
							}
						}

						a { 
							border: none;
							color: $secondary-text;
							font-weight: 500;

							&:hover {
								text-decoration: none;
							}
						}

						&.user-info {
							border-bottom: 0.5px solid #c5c2d5;
							background: linear-gradient(145deg, $primary-color-light-2, $primary-color-light-3);
							border-top-left-radius: 5px;
							border-top-right-radius: 5px;
							padding: 10px;
							
							span {
								color: $muted-text;
							}

							.email {
								font-weight: 600;
								color: $secondary-text;
							}
						}
					}
				}
			}
		}
	}
}