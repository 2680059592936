@import './../../../styles/colors';

.loading-page {
    .box {
        display: inline-block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 250px;
        height: 250px;
        margin: auto;
        
        .content {
            width: 500px;

            .divider {
                border-bottom: 5px solid $primary-color-light-2;
                border-radius: 5px;
            }

            .message {
                text-align: center;
                font-weight: 500;
                margin-top: 20px;
                color: $muted-text;
            }
        }
    }
}