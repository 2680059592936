@import './colors';

// This is a new bar added to only diagnostic component which have tabs inside it
.navbar--with--tabs {
	padding: 18px 0 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid $border-color;
	margin: 0 18px;

	.headings--container {
		padding-bottom: 10px;
		.small--heading {
			margin-bottom: 0;
			font-size: 12px;
			color: #a19eaa;
		}

		.main--heading {
			font-size: 16px;
		}
	}

	hr {
		margin: 0;
	}

	.navbar--horizontal--menu {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
		display: flex;
		align-items: flex-end;

		li {
			margin: 0 8px;
			display: flex;
			align-items: center;
		}

		a {
			font-size: 14px;
			line-height: 150%;
			color: $primary-text;
			display: flex;
			align-items: center;
			padding: 12px 18px;
			font-weight: 500;
			border: 1px solid transparent;
			border-bottom: 0;
			&:hover {
				text-decoration: none;
			}
		}

		.nav--tab--active {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			background-color: $white;
			color: $primary-color;
			border: 1px solid $border-color;
			border-bottom: 0;
			// margin-bottom: -2px;
			transform: translateY(3px);
		}
	}

	.search--container {
		padding-bottom: 10px;
	}

	.navbar--horizontal--menu {
		li {
			a {
				cursor: pointer;
			}
		}
	}
}

.select-dropdown-for-report {
	button {
		background: $primary-color;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 6px;
		font-size: 14px;
		font-weight: 500;
		min-width: 130px;
		max-height: 37px;

		&:disabled {
			background-color: $primary-color-light-2;

			.placeholder {
				color: $primary-color-light-2;
			}
		}

		.placeholder {
			color: $white;
			background: transparent;
			min-height: auto;
		}

		.value {
			color: $white;
		}
	}
}

.select-dropdown-for-projects {
	button {
		background: #bebebe1a;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		font-weight: 500;
		min-width: 130px;
		max-height: 37px;

		.placeholder {
			// background: transparent;
			color: black;
			min-height: auto;
		}

	}
}

.navbar--dropdown.line-dd {
	button {
		background: $primary-color-light-2;
		border: 1px solid $primary-color;
		border-radius: 5px;
		.placeholder, .value {
			color: $primary-color;
			font-weight: 400;
		}

		svg {
			fill: $primary-color;
			height: 20px;
			width: 20px;
		}
	}
}

.main--heading {
	font-size: 18px;
	font-weight: 500;
	color: $primary-text;
	margin-bottom: 0;
}

.diagnostic--page--wrapper {
	.col,
	.col-1,
	.col-10,
	.col-11,
	.col-12,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-auto,
	.col-lg,
	.col-lg-1,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-auto,
	.col-md,
	.col-md-1,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-md-auto,
	.col-sm,
	.col-sm-1,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-auto,
	.col-xl,
	.col-xl-1,
	.col-xl-10,
	.col-xl-11,
	.col-xl-12,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xl-auto {
		padding-left: 12px;
		padding-right: 12px;
	}
}

.timestamp {
	font-size: 16px;
	font-weight: 500;
	color: $primary-text;
	margin-bottom: 0;
}

.diagnostic--card {
	margin-bottom: 24px;
	display: flex;
	justify-content: space-between;

	.card--count {
		font-size: 20px;
		font-weight: 500;
		color: $primary-text;
		margin: 4px 0;
		line-height: 28px;
	}

	.card--headings {
		font-size: 14px;
		font-weight: 500;
		color: $primary-text;
		margin-bottom: 0;
		line-height: 24px;
		margin-right: 12px;
	}

	.card--text {
		font-size: 14px;
		font-weight: 400;
		color: $primary-text;
		margin-bottom: 0;
		line-height: 24px;
	}

	hr {
		margin: 6px 0;
	}

	.diagnostic--icon--container {
		width: 26px;
		height: 26px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
	}
}

.count-card {
	.card--container {
		padding: 12px 8px;
	}
}

.log--type--tag {
	padding: 0 7px;
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	border: 1px solid transparent;
	border-radius: 2em;
	margin-right: 8px;
	display: inline-block;
}

.log--event--title {
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 0;
}

.log--event--time {
	font-size: 12px;
	font-weight: 400;
	color: #555;
	margin: 0px 0 0 4px;
}

// TO CHANGE

.crash--data {
	font-family: 'Fira Code', monospace;
	background-color: $primary-color-light;
	padding: 5px 10px;
	cursor: text;

	p {
		font-size: 12px;
		margin-bottom: 0;
	}

	.highlight {
		font-weight: 500;
		font-size: 13px;
	}
}

// Rechart Overrides
.recharts-legend-wrapper {
	bottom: 0 !important;
}

.recharts-legend-item-text {
	font-size: 12px;
}

.node--logs {
	.flex {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.chevron {
		min-width: 20px;
	}

	.logtime {
		min-width: 110px;
	}

	.tag {
		min-width: 100px;
	}
}

.calendar--container {
	position: absolute;
	z-index: 100;
	top: 40px;
	right: 10px;
}

.calendar--display--btn {
	cursor: pointer;
	background: white;
	padding: 4px;
	border-radius: 1em;
}

.card-settings-panel {
	overflow-x: scroll;
	border: 1px solid $border-color;
	border-radius: 3px;
	padding: 6px 12px;
	margin-top: 8px;
	animation: fade_in_show 0.3s;
	transition: display 0.2s ease;
}

.selected-metric-tags-container {
	padding: 12px 0;

	.tags-list {
		display: flex;
	}

	.tag {
		padding: 4px 8px;
		font-size: 14px;
		line-height: 18px;
		border: 1px solid transparent;
		border-radius: 12px;
		margin-right: 8px;
		display: inline-block;
		background: #5330b91a;
		color: #5330b9;
		min-width: fit-content;
	}
}

@keyframes fade_in_show {
	0% {
		opacity: 0;
		transform: translateY(-50%);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.nestedlist-wrapper {
	.nestedlist-btn {
		background: $main-light-background;
		border: 1px solid $border-color;
		padding: 6px 12px;
		min-width: 10rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		font-weight: 500;
		margin-top: 6px;
		height: 44px;
	}

	.nestedlist-ul {
		background-color: $white;
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 0;
		font-size: 14px;
		// text-transform: capitalize;

		li {
			padding: 4px 0 0;
			margin-bottom: 4px;
		}
	}
}

.files-card {
	height: 51.67px;
}

.custom-tooltip {
	background-color: $white;
	padding: 12px;
	border: 1px solid rgb(204, 204, 204);
	font-size: 14px;

	p {
		margin-bottom: 0;
	}
}

.beta-tag {
	background-color: #5330b91a;
	color: #999;
	padding: 2px 3px;
	border-radius: 2px;
	font-size: 11px;
}

.react_time_range__time_range_container{

	padding: 30px 2% 10px !important;
	width: unset !important;

	
	.react_time_range__track{
		background-color: white !important;
		border-left: #ccc !important;
		border-right:#ccc !important
	}
	.react_time_range__handle_marker__error{
		background-color : $primary-color-dark !important
	}
	.react_time_range__rail__inner{
		background-color: unset !important;
	}
}

.log-legend{
	font-size: 15px;
    color: rgb(51, 51, 51);
    padding: 0 15px 0 15px;
   	cursor: pointer;
    opacity: 0.7;
	border-left: 5px solid;
}

.dashboard-lable{
	outline: none;
}