@import "./../../../../../../styles/colors";

.setting-header-wrapper {
  padding: 12px;
  background-color: $primary-color-light;
  display: flex;
  align-items: center;
  border-radius: 6px;

  .setting-icon-wrapper {
    margin-right: 8px;

    svg {
      color: $muted-text;
    }
  }
  label {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.5px;
    color: $primary-text;
    cursor: pointer;
  }

  &.secondary {
    padding: 8px;
    background-color: transparent;
    border: 1px solid $border-color;
    border-radius: 6px;
    &:hover {
      background-color: $primary-color-light;
    }
    label {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
    .setting-icon-wrapper {
        margin-right: 16px;
      svg {
        color: $primary-color;
      }
    }
  }

  .end-content-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: right;
  }
}
