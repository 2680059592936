@import './colors';

.react-calendar.insights-calendar {
    &.react-calendar--doubleView {
        width: 700px;
    }

    .react-calendar__viewContainer {
        display: flex;
    }

    button {
        &:hover:not(:disabled) {
            background: $main-light-background !important;
        }
    }
    .react-calendar__navigation {
        border-bottom: 1px solid $border-color;
        margin-bottom: 5px;
        button {
            border: none;
            background: transparent;

            &.react-calendar__navigation__arrow {
                padding: 0;
            }

            &.react-calendar__navigation__label {
                font-size: 14px;
                font-weight: 600;
                letter-spacing: -0.3px;
                color: $primary-text;
                padding: 5px;
            }

            svg {
                color: $muted-text;
            }

            &:disabled {
                svg {
                    color: $divider-color;
                }
            }
        }
    }

    .react-calendar__year-view {
        margin-top: 10px;
        .react-calendar__year-view__months {
            button {
                border: none;
                background: transparent;
                background-color: #edebf8;
                border: 0.5px solid #cbc3f8;

                abbr {
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }
    }

    .react-calendar__month-view__weekdays {
        .react-calendar__month-view__weekdays__weekday {
            text-align: center;
            margin-bottom: 5px;
            abbr {
                text-decoration: none;
                cursor: default;
                letter-spacing: -0.2px;
                font-size: 12px;
                font-weight: 600;
                color: $muted-text;
            }
        }
    }

    .react-calendar__month-view__days {
        button {
            border: 0.5px solid $border-color;
            // border-right: none;
            // border-bottom: none;
            background: transparent;
            padding: 1px 2px;

            abbr {
                font-size: 13px;
                color: $primary-text;
                font-weight: 500;
                letter-spacing: 0px;
                font-variant: normal;
                font-stretch: normal;
                text-shadow: none;
                text-transform: none;
            }

            &.react-calendar__month-view__days__day--neighboringMonth {
                height: 0px;
                border: 0px !important;
                padding: 0px !important;
            }

            &.react-calendar__tile--now {
                background-color: $main-light-background !important;
            }

            &.react-calendar__tile--range {
                &.react-calendar__tile--rangeStart,
                &.react-calendar__tile--rangeEnd {
                    background-color: #d3c4fd !important;
                    border: 0.5px solid #bcadeb;
                }
            }

            &.react-calendar__tile--range {
                background-color: #edebf8;
                border: 0.5px solid #cbc3f8;
            }

            &:disabled {
                abbr {
                    color: #879596;
                }
            }
        }
    }

    &.single-date {
        .react-calendar__month-view  {
            margin: 0px;
        }
    }
}