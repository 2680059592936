@import './../../../../../../../styles/colors';

.stats-preview-wrapper {
    .total-usage {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: linear-gradient(210deg, #f0fbf0, #e9efe9);
        padding: 10px;
        border-radius: 5px;
        border: 1px dotted #e9efe9;
        height: 100%;

        .icon {
            color: $muted-text;
        }

        .count {
            color: #4e5e4e;
            margin: 0px;
            font-family: "PoppinsM";
            letter-spacing: 0px;
            font-weight: 600;
            line-height: normal;
        }

        .text {
            font-size: 12px;
            font-weight: 600;
            color: $muted-text;
            letter-spacing: -0.2px;
            text-align: center;
        }
    }
}