.global-filters-container {
    padding: 0px 18px;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;

    .clear-filters-button {
        border-radius: 0;

        &, &:hover {
            border-left: 0px;
            border-color: transparent;
        }

        &:disabled {
            cursor: not-allowed;

            &:hover {
                color: #c2303e;
            }
        }
    }

    .add-filter-container {
        .add-filter {
            > div {
                z-index: 98 !important;
            }
        }
    }

    .filter-tags-container {
        flex-grow: 1;
        background-color: #edebf8;
        border: 1px solid transparent;
        border-left: 0px;
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
        display: flex;
        align-items: center;
        padding-left: 5px;
        font-size: 80% !important;
        overflow: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;
          }
    }
}