.info-message-wrapper {
    font-size: 12px;
    line-height: 24px;
    border-left-width: 5px;
    border-left-style: solid;
    padding: 10px 25px 10px 15px;
    letter-spacing: 0.2px;

    &.warning {
        box-shadow: 1px 1px 5px #ebe9df;
        background-color: #ffe5644d;
        background: linear-gradient(270deg, #ffe56452, #ffe5644d);
        border-left-color: #e9d605;
    }
    
    &.error {
        box-shadow: 1px 1px 5px #ebdfdf;
        background-color: #f8d7daa9;
        background: linear-gradient(270deg, #f8d7da9d, #f8d7daa9);
        border-left-color: #f89f9f;
    }
    
    &.info {
        box-shadow: 1px 1px 5px #dfe6eb;
        background-color: #d7eaf8a9;
        background: linear-gradient(270deg, #d7edf89d, #d7f0f8a9);
        border-left-color: #9fbdf8;
    }
    
    &.success {
        box-shadow: 1px 1px 5px #dfebe0;
        background-color: #d7f8dfa9;
        background: linear-gradient(270deg, #daf8d79d, #d7f8dea9);
        border-left-color: #8cd09a;
    }
}