@import './colors';

.statistics {
	.diagnostic--card {
		height: 300px;
	}
}

/* ------------------------------------------------------------------------ */
/* ------------------------ Filter Styles Starts -------------------------- */

.filter--dropdown {
	min-width: 140px;
	min-height: 35px;
	font-size: 14px;
}

.daily--count--calender {
	position: relative;

	.button {
		min-width: 140px;
		position: relative;
		box-sizing: border-box;
		background-color: $white;
		border: 1px solid #e3ebf6;
		border-radius: 4px;
		box-shadow: 0;
		cursor: default;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		min-height: 35px;
		outline: 0 !important;
		transition: all 100ms;
		font-size: 14px;
		margin-right: 8px;

		.text {
			display: flex;
			align-items: center;
			flex: 1;
			flex-wrap: wrap;
			padding: 2px 8px;
			-webkit-overflow-scrolling: touch;
			position: relative;
			overflow: hidden;
			box-sizing: border-box;
			color: #333;
		}

		.icon {
			align-items: center;
			align-self: stretch;
			display: flex;
			flex-shrink: 0;
			box-sizing: border-box;
		}
	}

	.calender--wrapper {
		position: absolute;
		top: 100%;
		left: -100%;
		z-index: 400;
	}
}

.filter--apply--btn {
	padding: 4px 6px;
	height: 31px;
	width: 35px;
}

/* ------------------------ Filter Styles Ends -------------------------- */
/* ---------------------------------------------------------------------- */

/* -------------------------------------------------------------------------------- */
/* ------------------------ Online Offline Widget Starts -------------------------- */

.online--offline {
	.total-text {
		font-size: 14px;
		color: #37375b;
		font-weight: 500;
		background-color: $primary-color-light;
		padding: 4px;
		border-radius: 4px;
		margin-bottom: 0;
	}

	.legend {
		display: flex;
		padding: 8px 0;

		.count {
			font-size: 20px;
			line-height: 28px;
			color: #37375b;
			font-weight: 500;
		}

		.count-text {
			font-size: 14px;
			text-transform: capitalize;
			color: rgba(55, 55, 91, 0.8);
		}

		.color-circle-container {
			padding: 10px 5px;
		}

		.color-circle {
			width: 10px;
			height: 10px;
			display: block;
			border-radius: 50%;
		}

		p {
			margin-bottom: 0;
		}
	}
}

/* ------------------------ Online Offline Widget Ends -------------------------- */
/* -------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------- */
/* ------------------------ Graph Widget Starts -------------------------- */
.stats--graphs {
	.recharts-wrapper {
		margin-left: -1rem;

		svg {
			overflow: visible;
		}
	}
	.recharts-legend-item-text {
		color: $primary-text !important;
	}
	.recharts-default-legend {
		margin-bottom: -8px !important;
	}
	.bar-graph-legend {
		margin-top: 32px;
		font-size: 14px;
		text-transform: capitalize;
	}

	.graph-legend-circle {
		width: 10px;
		height: 10px;
		display: block;
		border-radius: 50%;
		margin-right: 8px;
	}
}
/* ------------------------- Graph Widget Ends --------------------------- */
/* ----------------------------------------------------------------------- */
