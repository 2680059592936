@import './../../../../styles/colors';

.widget-wrapper {
    border-radius: 5px;
    margin-bottom: 30px;
    box-shadow: 1px 1px 5px 0px #e3ebf6;

    &.loading {
        background: #edebf8;
        background: linear-gradient(110deg, #edebf8 8%, #f7f6fa 18%, #edebf8 33%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 750ms shine linear infinite;
        min-height: 200px;
    }

    &.complete {
        height: auto !important;
        background-color: #ffffff;
    }

    .heading {
        padding: 15px 20px;
        display: flex;

        .text {
            font-size: 18px;
            font-weight: 400;
            color: $primary-text;
            text-shadow: 1px 1px $border-color;
            display: flex;
            align-items: center;
        }

        .expand-icon {
            flex-grow: 1;
            display: flex;
            justify-content: right;
            align-items: center;

            button {
                color: $muted-text;
                padding: 5px;
                border: none;
                outline: none;
                background-color: transparent;
                border-radius: 50px;

                &:hover {
                    background: $primary-color-light-2;
                }
            }
        }
    }

    .component-container.show {
        position: relative;
        opacity: 1;
    }

    .component-container.hide {
        position: absolute;
        opacity: 0;
        left: -9999px;
    }

    &.title .component-container{
        border-top: 1px solid $primary-color-light-2 !important;
        .loader {
            width: 100%;
            position: relative;
            padding: 0.5px;
            background: transparent;

            &:before {
                content: '';
                border: 1px solid transparent; 
                position: absolute;
                top: -4px; 
                right: -4px; 
                bottom: -4px; 
                left: -4px;
            }

            .bar { 
                position: absolute;
                top: 0;
                right: 100%;
                bottom: 0;
                left: 0;
                background: $primary-color-dark; 
                width: 0;
                animation: loadingBar 850ms linear infinite;
            }
        }

        .main-content {
            padding: 15px 20px;
        }
    }

    @keyframes shine {
        to {
            background-position-x: -200%;
        }
    }
}