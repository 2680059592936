@import './../../../../../../styles/colors';

.credits-usage-calculation-help-wrapper {
    border: 1px solid transparent;
    border-radius: 5px;

    .title {
        font-weight: 600;
        letter-spacing: -0.5px;
        display: flex;
        justify-content: right;
        align-items: center;
        cursor: pointer;
        color: $primary-color;

        &:hover {
            label {
                text-decoration: underline;
            }
        }

        svg {
            margin-right: 2px;
        }

        label {
            cursor: inherit;
            font-size: 14px;
            margin: 0px;
        }
    }

    .data {
        margin-top: 15px;
    }
}