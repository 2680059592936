@import './colors';

.container-fluid {
	padding-left: 0;
	padding-right: 0;
}

a:hover {
	color: $primary-color;
}

a:focus,
button:focus {
	outline: 0 !important;
}
.spinner-border {
	width: 1rem;
	height: 1rem;
	border: 2px solid currentColor;
	border-right-color: rgba(255, 255, 255, 0.2);
	border-radius: 50%;
	-webkit-animation: spinner-border 0.75s linear infinite;
	animation: spinner-border 0.75s linear infinite;
}

.css-1hwfws3 {
	font-size: 14px;
}

.css-kj6f9i-menu {
	font-size: 14px;
}

.css-1szy77t-control {
	background-color: $primary-color-light;
}
